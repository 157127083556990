export const headers = {
  reporte1: {
    widths: [30, 220, 90, 40, 40, 40],
    headerRows: 2,
    body: [
      [{
          text: 'Núm.',
          rowSpan: 2,
          margin: [0, 5, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Integrantes',
          alignment: 'center',
          rowSpan: 2,
          margin: [0, 5, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Participaciones',
          rowSpan: 2,
          margin: [0, 5, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Tiempos de participación',
          colSpan: 3,
          fillColor: '#c7c7c7'
        },
        {}, {}
      ],
      ["", "", "", {
        text: 'Hrs',
        fillColor: '#c7c7c7'
      }, {
        text: 'Min',
        fillColor: '#c7c7c7'
      }, {
        text: 'Seg',
        fillColor: '#c7c7c7'
      }],
    ]
  },
  reporte2: {
    widths: [220, 60, 90, 30, 30, 30],
    headerRows: 2,
    body: [
      [{
          text: 'Punto del orden del día',
          rowSpan: 2,
          margin: [0, 10, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Estatus',
          alignment: 'center',
          rowSpan: 2,
          margin: [0, 10, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Participaciones',
          rowSpan: 2,
          margin: [0, 10, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Duración',
          alignment: 'center',
          colSpan: 3,
          fillColor: '#c7c7c7'
        },
        {}, {}
      ],
      ["", "", "", {
        text: 'Hrs',
        fillColor: '#c7c7c7'
      }, {
        text: 'Min',
        fillColor: '#c7c7c7'
      }, {
        text: 'Seg',
        fillColor: '#c7c7c7'
      }],
    ]
  },
  reporte3: {
    widths: [80, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 40],
    headerRows: 3,
    body: [
      [{
          text: 'Integrantes',
          alignment: 'center',
          fontSize: 9,
          rowSpan: 3,
          margin: [0, 15, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Conducción',
          fontSize: 9,
          alignment: 'center',
          rowSpan: 2,
          colSpan: 3,
          margin: [0, 8, 0, 0],
          fillColor: '#c7c7c7'
        },
        {},
        {},
        {
          text: 'Rondas',
          fontSize: 9,
          alignment: 'center',
          colSpan: 9,
          margin: [0, 1, 0, 0],
          fillColor: '#c7c7c7'
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {
          text: 'Total por punto',
          fontSize: 9,
          alignment: 'center',
          colSpan: 2,
          margin: [0, 1, 0, 0],
          fillColor: '#c7c7c7'
        },


        {}
      ],
      [{},
        {},
        {},
        {},
        {
          text: 'Primera',
          fontSize: 9,
          alignment: 'center',
          colSpan: 3,
          fillColor: '#c7c7c7'
        },
        {},
        {},
        {
          text: 'Segunda',
          fontSize: 9,
          alignment: 'center',
          colSpan: 3,
          fillColor: '#c7c7c7'
        },
        {},
        {},
        {
          text: 'Tercera',
          fontSize: 9,
          alignment: 'center',
          colSpan: 3,
          fillColor: '#c7c7c7'
        },
        {},
        {},
        {text: '',fillColor: '#c7c7c7'},
        {text: '',fillColor: '#c7c7c7'}
      ],
      ["", {
          text: "Núm",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Min",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Seg",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Part",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Min",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Seg",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Part",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Min",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Seg",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Part",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Min",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Seg",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "Part",
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: "hh:mm:ss",
          fontSize: 9,
          fillColor: '#c7c7c7'
        }
      ],
    ]
  },
  reporteA: {
    widths: [30, 220, 40, 40, 30, 30, 30, 30],
    headerRows: 2,
    body: [
      [{
          text: 'Núm.',
          rowSpan: 2,
          margin: [0, 10, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Integrantes',
          alignment: 'center',
          rowSpan: 2,
          margin: [0, 10, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Tiempos de participación',
          alignment: 'center',
          colSpan: 6,
          fillColor: '#c7c7c7'
        },
        {}, {}, {}, {}, {}
      ],
      ["", "",
        {
          text: 'Punto',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Ronda',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Tipo',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Hrs',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Min',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Seg',
          fillColor: '#c7c7c7'
        }
      ]
    ]
  },
  reporteB: {
    widths: [130, 60, 45, 60, 45, 63,50],
    headerRows: 2,
    body: [
      [{
          text: 'Integrantes',
          alignment: 'center',
          rowSpan: 2,
          fontSize: 9,
          margin: [0, 5, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Intervenciones',
          alignment: 'center',
          fontSize: 9,
          rowSpan: 2,
          margin: [0, 5, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Duración hh:mm:ss',
          fontSize: 9,
          alignment: 'center',
          margin: [0, 2, 0, 0],
          rowSpan: 2,
          fillColor: '#c7c7c7'
        },
        {
          text: 'Conducciones',
          fontSize: 9,
          alignment: 'center',
          margin: [0, 5, 0, 0],
          rowSpan: 2,
          fillColor: '#c7c7c7'
        },
        {
          text: 'Duración hh:mm:ss',
          fontSize: 9,
          alignment: 'center',
          margin: [0, 2, 0, 0],
          rowSpan: 2,
          fillColor: '#c7c7c7'
        },
        {
          text: 'Total de participaciones',
          fontSize: 9,
          alignment: 'center',
          colSpan: 2,
          fillColor: '#c7c7c7'
        },
        {}
      ],
      ["", "","","","",
        {
          text: 'Participaciones',
          fontSize: 9,
          fillColor: '#c7c7c7'
        },
        {
          text: 'hh:mm:ss',
          alignment: 'center',
          fontSize: 9,
          fillColor: '#c7c7c7'
        }
      ]
    ]
  },
  reporte6: {
    widths: [30, 175, 40, 90, 30, 30, 30],
    headerRows: 2,
    body: [
      [{
          text: 'Núm.',
          rowSpan: 2,
          margin: [0, 10, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Integrantes',
          alignment: 'center',
          rowSpan: 2,
          margin: [0, 10, 0, 0],
          fillColor: '#c7c7c7'
        },
        {
          text: 'Mociones',
          alignment: 'center',
          colSpan: 5,
          fillColor: '#c7c7c7'
        },
        {}, {}, {}, {}
      ],
      ["", "",
        {
          text: 'Punto',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Participaciones',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Hrs',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Min',
          fillColor: '#c7c7c7'
        },
        {
          text: 'Seg',
          fillColor: '#c7c7c7'
        }
      ]
    ]
  }
}
