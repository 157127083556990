import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ExcelService } from '../../servicios/excel/excel.service';
import { ReportespdfService } from '../../servicios/pdf/reportespdf.service';
import { ReportesService } from '../../servicios/reportes/reportes.service';
import { MenuService } from '../../servicios/menu/menu.service';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})

export class ReportesComponent implements OnInit {

    tipoSesion = ''
    formReportes: FormGroup;

    tipoReportes = [{
        id: 1,
        tipo:'Resumen de Participantes'
    },{
        id: 2,
        tipo:'Resumen por puntos de Orden del Día'
    },{
        id: 3,
        tipo:'Intervenciones por Punto y Ronda'
    },{
        id: "A",
        tipo:'Listado de Participaciones'
    },{
        id: "B",
        tipo:'Resumen de conducción e intervenciones del Consejero Presidente Y del Secretario Ejecutivo'
    },{
        id: 6,
        tipo:'Mociones'
    }//,
    // {
    //     id: 7,
    //     tipo:'Mociones'
    // }
  ]
    //
    // reporte1 = [{
    //   "NUMERO CONSECUTIVO":"1",
    //   "INTEGRANTES":"Chocko",
    //   "PARTICIPANTES":"8",
    //   "TIEMPOS PARTICIPACION": "00:01:16"
    // }]
    //
    // reporte2 = [{
    //   "PUNTO":"Informe sobre el cumplimiento y avance de las actividades establecidas en el Plan Integral y los Calendarios de Coordinación de los Procesos Electorales Locales 2019-2020, en las entidades de Coahuila e Hidalgo.",
    //   "ESTATUS":"A favor",
    //   "PARTICIPANTES":"18",
    //   "DURACION": "00:02:07"
    // }]
    //
    // reporte3 = [{
    //   "PUNTO":"Informe sobre el cumplimiento y avance de las actividades establecidas en el Plan Integral y los Calendarios de Coordinación de los Procesos Electorales Locales 2019-2020, en las entidades de Coahuila e Hidalgo.",
    //   "INTEGRANTES":"Dip. Claudia Pastor Badilla	",
    //   "CONDUCCION PARTICIPANTES":"",
    //   "CONDUCCION TIEMPO": "",
    //   "PRIMERA RONDA":"",
    //   "PRIMERA RONDA TIEMPO":"",
    //   "SEGUNDA RONDA":"1",
    //   "SEGUNDA RONDA TIEMPO":"00:00:05",
    //   "TERCERA RONDA":"",
    //   "TERCERA RONDA TIEMPO":"",
    //   "TOTAL PARTICIPACIONE":"1",
    //   "TIEMPO TOTAL":"00:00:05"
    // }]
    //
    // reporte4 = [{
    //   "NUMERO CONSECUTIVO":"1",
    //   "CLAVE":"1",
    //   "INTEGRANTES":"Chocko",
    //   "PARTICIPANTE":"1",
    //   "DURACION": "00:01:16"
    // }]
    // reporteA = [{
    //   "ORDEN":"1",
    //   "INTEGRANTES":"Chocko",
    //   "PUNTO":"Inicio",
    //   "RONDA":"Inicial",
    //   "TIPO": "P",
    //   "DURACION": "00:00:20"
    // }]
    // reporteB = [{
    //   "DESCRIPCIÓN":"Inicio",
    //   "PARTICIPANTES":"Chocko",
    //   "INTERVENCIONES":"1",
    //   "TIEMPO INTERVENCIONES":"00:00:20",
    //   "CONDUCCIÓN": "",
    //   "TIEMPO CONDUCCIÓN": "",
    //   "TOTAL CONTEO":"1",
    //   "TIEMPO TOTAL":"00:00:20"
    // }]



    constructor(private _menuService:MenuService, private _formBuilder: FormBuilder, private _excelService:ExcelService,private _reportespdfService:ReportespdfService,
       private _reportesService:ReportesService, private toastr: ToastrService) {
        const formControls = this.tipoReportes.map(control => new FormControl(false));

        // Create a FormControl for the select/unselect all checkbox
        const selectAllControl = new FormControl(false);

        // Add the selectAllControl separetely
        this.formReportes = this._formBuilder.group({
            tipoReportes: new FormArray(formControls),
            selectAll: selectAllControl
        });
    }

    ngOnInit() {
        this._menuService.verMenu(true);
    }

    sesion(valSesion:any) {
        this.tipoSesion = valSesion;
    }



    idReportes() {
      if(this.tipoSesion ==''){
        this.toastr.warning("Debes de seleccionar una sesión.");
      }else{
        // Filtramos ids de reportes
        const reportesSeleccionados  = this.formReportes.value.tipoReportes
        .map((checked, index) => checked ? this.tipoReportes[index].id : null)
        .filter(value => value !== null);
        // Do something with the result
        if( reportesSeleccionados.length == 0){
          this.toastr.warning("Debes de seleccionar al menos un reporte.");
        }else{
          this.obtenerReportes(reportesSeleccionados);
        }
      }
    }

    obtenerReportes(arregloReportes){
      this._reportesService.getReportesPorSesion(this.tipoSesion).subscribe(data =>{
          this._reportespdfService.generatePdf(data, arregloReportes);
      }, err =>{
        console.log(err)
      }
    )

    }

}
