import { Injectable, IterableDiffers } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from './user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantesService } from "../constantes/constantes.service";
import { SpinnerService } from "../spinner/spinner.service";
import * as jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  url;
  public nombreUsuario:"";
  public rol: "";
  public errorLogin = false;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(private router: Router, private httpClient: HttpClient, private _constantesService:ConstantesService, private _spinnerService:SpinnerService) {
    this.url = this._constantesService.API_ENDPOINT;
  }

  login(user: User) {
    this.errorLogin = false;
   if (user.userName !== '' && user.password !== '' ) {
     this.postUser(user.userName, user.password).subscribe(( data)=>{
       
      if (data.body['status'] == 200 ){
         let decoded = jwt_decode(data.body['authorities']); 
         this._spinnerService.hideSpinner();
         this.loggedIn.next(true);
         this.nombreUsuario = decoded['sub'];
         let arrayTempRoles = decoded['authorities'].filter((item) =>  item.includes('SIMETIS') );
         this.rol = arrayTempRoles[0];
         this._constantesService.token= `${ data.body['authorities'] }` 
         
         this.router.navigate(['/home']);
      }else{

        this.errorLogin = true;
      }
     },(err)=>{
       this._spinnerService.hideSpinner();
       this.errorLogin = true;
     })

   }
 }

  logout() {
    this.loggedIn.next(false);
    this._constantesService.token = '';
    this.router.navigate(['/login']);
  }

  postUser(usuario, password){
    let usuarioSesion = {
      'usuario': usuario,
      'contrasena': password

    };

    // return this.httpClient.post(`${this.url}/login`,usuarioSesion, {
    return this.httpClient.post(`${this.url}/usuario`,usuarioSesion, {
      headers: new HttpHeaders({
           'Content-Type':  'application/json',
          //  'username':  usuario,
          //  'password':  password
         }),
         observe: 'response'
    })
  }
}
