import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantesService } from "../constantes/constantes.service";

@Injectable({
  providedIn: 'root'
})
export class ObtenerTiemposSesionService {

  url;
  tiemposSesiones = {
    "idSesion": 1,
    "fechaHora": 1573002209587,
    "puntosSesion": [{
      "idPuntoOrden": 1,
      "claveOrden": "1",
      "descripcion": "jhgdasjghdasjghsadjghksajhkgsad",
      "visible": 1,
      "usuario": "prueba.ine",
      "fechaHora": 1572912668055,
      "estatusPunto": "C"
    }, {
      "idPuntoOrden": 2,
      "claveOrden": "2",
      "descripcion": "lkjsadkhsakjhsadkjhsakjhsad",
      "visible": 1,
      "usuario": "prueba.ine",
      "fechaHora": 1572912668055,
      "estatusPunto": "C"
    }],
    "tiemposSesion": [{
      "tiempo": 1,
      "turno": 1,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 0,
      "idParticipante": 1
    }, {
      "tiempo": 0,
      "turno": 2,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 0,
      "idParticipante": 2
    }, {
      "tiempo": 1,
      "turno": 3,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 0,
      "idParticipante": 3
    }, {
      "tiempo": 2,
      "turno": 4,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 1,
      "idParticipante": 1
    }, {
      "tiempo": 1,
      "turno": 5,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 1,
      "idParticipante": 3
    }, {
      "tiempo": 2,
      "turno": 6,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 1,
      "idParticipante": 14
    }, {
      "tiempo": 1,
      "turno": 7,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 2,
      "idParticipante": 2
    }, {
      "tiempo": 1,
      "turno": 8,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 2,
      "idParticipante": 3
    }, {
      "tiempo": 1,
      "turno": 9,
      "idRonda": "I",
      "idTipoParticipacion": "1",
      "idPuntoOrden": 2,
      "idParticipante": 11
    }]
  }

  constructor(private httpClient: HttpClient, private _constantesService:ConstantesService) {
    this.url = this._constantesService.API_ENDPOINT;
  }
  getTiemposPorSesion(id){
    return this.httpClient.get(`${this.url}/obtenerTiemposPorSesion?idSesion=${id}`);
  }
}
