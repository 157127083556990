import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ConstantesService } from '../constantes/constantes.service';

@Injectable({
  providedIn: 'root'
})
export class EliminarPartidoService {

  url;
  constructor(private httpClient: HttpClient, private _constantesService:ConstantesService) {
    this.url = this._constantesService.API_ENDPOINT;
  }

  eliminarPartido(idPartido){
    return this.httpClient.get(`${this.url}/eliminarPartidoRepresentantes?idPartido=${idPartido}`)
  }
}
