import { Component, OnInit } from "@angular/core";
import { IntegrantesService } from "../../servicios/integrantes/integrantes.service";
import { ObtenerCargosService } from "../../servicios/obtenerCargos/obtener-cargos.service";
import { ObtenerTratamientosService } from "../../servicios/obtenerTratamientos/obtener-tratamientos.service";
import { CrearConsejerosService } from "../../servicios/crearConsejero/crear-consejeros.service";
import { EditarConsejeroService } from "../../servicios/editarConsejero/editar-consejero.service";
import { EliminarConsejeroService } from "../../servicios/eliminarConsejero/eliminar-consejero.service";
import { CrearPartidoService } from "../../servicios/crearPartido/crear-partido.service";
import { EditarPartidoService } from "../../servicios/editarPartido/editar-partido.service";
import { EliminarPartidoService } from "../../servicios/eliminarPartido/eliminar-partido.service";
import { SpinnerService } from "../../servicios/spinner/spinner.service";
import { AuthService } from "../../servicios/auth/auth.service";
import * as shajs from "sha.js";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-configuracion",
  templateUrl: "./configuracion.component.html",
  styleUrls: ["./configuracion.component.scss"]
})
export class ConfiguracionComponent implements OnInit {
  consejeros = [];
  partidos = [];
  poderLegislativo = [];
  cargos;
  tratamientos;
  public banderaAgregar = false;
  public consejeroEdicion = {};
  public partidoEdicion = {};
  public partidoEdicionId = "";
  public consejeroAEliminar = {};
  public representanteAEliminar = "";
  public partidoAEliminar = {};
  public partidoAEliminarId = "";
  public representanteEdicion = {};
  public representanteEdicionId = "";
  public editarConsejero: boolean = false;
  public editarPartido: boolean = false;

  constructor(
    private _integrantesService: IntegrantesService,
    private _spinnerService: SpinnerService,
    private _obtenerTratamientosServicev: ObtenerTratamientosService,
    private _obtenerCargosService: ObtenerCargosService,
    private _crearConsejerosService: CrearConsejerosService,
    private _editarConsejerosService: EditarConsejeroService,
    private _eliminarConsejeroService: EliminarConsejeroService,
    private _authService: AuthService,
    private toastr: ToastrService,
    private _crearPartidoService: CrearPartidoService,
    private _editarPartidoService: EditarPartidoService,
    private _eliminarPartidoService: EliminarPartidoService
  ) {
    this.obtenerParticipantes();
    this.obtenerCargos();
    this.obtenerTratamientos();
  }

  ngOnInit() {}

  obtenerParticipantes() {
    this._spinnerService.showSpinner();
    this._integrantesService.getParticipantes().subscribe(
      data => {
        this.consejeros = data["consejeros"];
        this.partidos = data["partidos"];
        this.partidos.forEach( partido => {
          let index = data["poderLegislativo"].findIndex(partidoPL => partidoPL.idPartidoPolitico == partido["idPartidoPolitico"]);
          partido.representantes = partido.representantes.concat(data["poderLegislativo"][index].representantes);
        })
        this.poderLegislativo = data["poderLegislativo"];
        this._spinnerService.hideSpinner();

      },
      err => {
        this._spinnerService.hideSpinner();
        console.log(err);
      }
    );
  }

  obtenerCargos() {
    this._spinnerService.showSpinner();
    this._obtenerCargosService.getCargos().subscribe(
      data => {
        this.cargos = data;
        this._spinnerService.hideSpinner();
      },
      err => {
        console.log(err);
        this._spinnerService.hideSpinner();
      }
    );
  }

  obtenerTratamientos() {
    this._spinnerService.showSpinner();
    this._obtenerTratamientosServicev.getTratamientos().subscribe(
      data => {
        this.tratamientos = data;
        this._spinnerService.hideSpinner();
      },
      err => {
        console.log(err);
        this._spinnerService.hideSpinner();
      }
    );
  }

  //img

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    if (file.type == "image/jpeg" || file.type == "image/jpg") {
      myReader.onloadend = e => {
        if (this.editarPartido) {
          this.partidoEdicion["imagen"] = myReader.result;
          this.partidoEdicion["sha"] = new shajs.sha256()
            .update(this.partidoEdicion["imagen"])
            .digest("hex");
          this.partidoEdicion["tipoImg"] = file.type;
        } else {
          this.consejeroEdicion["imagen"] = myReader.result;
          this.consejeroEdicion["sha"] = new shajs.sha256()
            .update(this.consejeroEdicion["imagen"])
            .digest("hex");
          this.consejeroEdicion["tipoImg"] = file.type;
        }
      };
      myReader.readAsDataURL(file);
    } else {
      this.toastr.warning("El tipo de archivo no es permitido", "Sólo .jpg");
    }
  }
  //Configuración Consejeros
  editarConsejeros(id) {
    this.editarConsejero = true;
    this.consejeroEdicion = this.consejeros[id];
  }

  cancelarEdicionConsejero() {
    this.editarConsejero = false;
    this.consejeroEdicion = {};
  }

  agregarCandidato() {
    this.editarConsejero = true;
  }

  guardarConsejero(consejero, formData) {
    if (
      formData.controls.idCargo.valid &&
      formData.controls.idTratamiento.valid &&
      formData.controls.nombreParticipante.valid &&
      formData.controls.primerApellido.valid
    ) {
      this.usuarioFecha();
      this._spinnerService.showSpinner();
      this._crearConsejerosService.guardarConsejero(consejero).subscribe(
        data => {
          this.toastr.success("¡El consejero fue guardado exitosamente!");
          this.editarConsejero = false;
          this.obtenerParticipantes();
          this._spinnerService.hideSpinner();
        },
        err => {
          console.log(err);
          this._spinnerService.hideSpinner();
        }
      );
      formData.controls.idCargo.touched = false;
      formData.controls.idTratamiento.touched = false;
      formData.controls.nombreParticipante.touched = false;
      formData.controls.primerApellido.touched = false;
    } else {
      formData.controls.idCargo.touched = true;
      formData.controls.idTratamiento.touched = true;
      formData.controls.nombreParticipante.touched = true;
      formData.controls.primerApellido.touched = true;
    }
  }

  actualizarConsejero(consejero, formData) {
    if (
      formData.controls.idCargo.valid &&
      formData.controls.idTratamiento.valid &&
      formData.controls.nombreParticipante.valid &&
      formData.controls.primerApellido.valid
    ) {
      consejero["visible"] = "1";
      this.usuarioFecha();
      this._spinnerService.showSpinner();
      this._editarConsejerosService.actualizarConsejero(consejero).subscribe(
        data => {
          this.toastr.success("¡El consejero fue actualizado exitosamente!");
          this.editarConsejero = false;
          this.obtenerParticipantes();
          this._spinnerService.hideSpinner();
        },
        err => {
          console.log(err);
          this._spinnerService.hideSpinner();
        }
      );
      formData.controls.idCargo.touched = false;
      formData.controls.idTratamiento.touched = false;
      formData.controls.nombreParticipante.touched = false;
      formData.controls.primerApellido.touched = false;
    } else {
      formData.controls.idCargo.touched = true;
      formData.controls.idTratamiento.touched = true;
      formData.controls.nombreParticipante.touched = true;
      formData.controls.primerApellido.touched = true;
    }
  }

  confirmarEliminacionConsejero(consejero) {
    this.consejeroAEliminar = consejero;
  }

  eliminarConsejero() {
    this._spinnerService.showSpinner();
    this._eliminarConsejeroService
      .eliminarConsejero(this.consejeroAEliminar["idParticipante"])
      .subscribe(
        data => {
          if (data["codigo"] == "202") {
            this.toastr.warning(data["mensaje"]);
          } else {
            this.toastr.success("¡El consejero fue eliminado exitosamente!");
            this.obtenerParticipantes();
          }
          this._spinnerService.hideSpinner();
        },
        err => {
          console.log(err);
          this._spinnerService.hideSpinner();
        }
      );
  }
  //Configuración Patidos

  //Representantes
  nuevoRepresentante(id) {
    this.representanteEdicionId = "";
    this.representanteEdicion = {};
  }

  editarRepresentante(id) {
    this.representanteEdicionId = id;
    this.representanteEdicion = this.partidoEdicion["representantes"][id];
  }

  agregarRepresentante(representante, formData) {
    if (
      formData.controls.nombreParticipante.valid &&
      formData.controls.primerApellido.valid &&
      formData.controls.tratamiento.valid
    ) {
      if (!this.partidoEdicion.hasOwnProperty("representantes")) {
        this.partidoEdicion["representantes"] = [];
      }
      let d = new Date();
      representante["fechaHora"] = d.getTime();
      representante["usuario"] =
        this._authService.nombreUsuario || "carga.inicial";
      representante["visible"] = "1";
      this.partidoEdicion["representantes"].push(representante);
      this.representanteEdicion = {};
      formData.controls.nombreParticipante.touched = false;
      formData.controls.primerApellido.touched = false;
      formData.controls.tratamiento.touched = false;
      let element: HTMLElement = document.getElementById(
        "closeModalRep"
      ) as HTMLElement;
      element.click();
    } else {
      formData.controls.nombreParticipante.touched = true;
      formData.controls.primerApellido.touched = true;
      formData.controls.tratamiento.touched = true;
    }
  }

  atualizarRepresentante(a, formData) {
    if (
      formData.controls.nombreParticipante.valid &&
      formData.controls.primerApellido.valid &&
      formData.controls.tratamiento.valid
    ) {
      this.representanteEdicion["visible"] = "1";
      this.partidoEdicion["representantes"][
        this.representanteEdicionId
      ] = this.representanteEdicion;
      formData.controls.nombreParticipante.touched = false;
      formData.controls.primerApellido.touched = false;
      formData.controls.tratamiento.touched = false;
      let element: HTMLElement = document.getElementById(
        "closeModalRep"
      ) as HTMLElement;
      element.click();
    } else {
      formData.controls.nombreParticipante.touched = true;
      formData.controls.primerApellido.touched = true;
      formData.controls.tratamiento.touched = true;
    }
  }

  confirmarRepresentante(i) {
    this.representanteAEliminar = i;

  }
  eliminarRepresentante() {
    this.partidoEdicion["representantes"].splice(
      this.representanteAEliminar,
      1
    );
  }

  //Partidos
  agregarPartido() {
    this.partidoEdicionId = "";
    this.editarPartido = true;
  }

  editarPartidos(id) {
    this.editarPartido = true;
    this.partidoEdicionId = id;
    this.partidoEdicion = this.partidos[id];
  }

  guardarPartido(formData) {
    if (formData.controls.Nombre.valid && formData.controls.siglas.valid) {
      if (!this.partidoEdicion.hasOwnProperty("representantes")) {
        this.partidoEdicion["representantes"] = [];
      }

      this.usuarioFecha();
      this._spinnerService.showSpinner();
      this._crearPartidoService.postPartido(this.partidoEdicion).subscribe(
        data => {
          this.toastr.success(
            "¡El partido político fue guardado exitosamente!"
          );
          this.editarPartido = false;
          this.obtenerParticipantes();
          this.partidoEdicion = {};
          console.log(data);
          this._spinnerService.hideSpinner();
        },
        err => {
          console.log(err);
          this._spinnerService.hideSpinner();
        }
      );

      formData.controls.Nombre.touched = false;
      formData.controls.siglas.touched = false;
    } else {
      formData.controls.Nombre.touched = true;
      formData.controls.siglas.touched = true;
    }
  }

  actualizarPartido(formData) {
    if (formData.controls.Nombre.valid && formData.controls.siglas.valid) {
      this.usuarioFecha();
      this.partidoEdicion["representantes"].forEach(representante => {
        if (representante.visible == null) {
          representante.visible = "1";
        }
      });
      this._spinnerService.showSpinner();
      this._editarPartidoService.editarPartido(this.partidoEdicion).subscribe(
        data => {
          console.log(data)
          if(data["codigo"] =="202"){
            this.toastr.warning(
              data["mensaje"]
            );
          }else{
            this.toastr.success(
              "¡El partido político fue actualizado exitosamente!"
            );
          }


          this.obtenerParticipantes();
          this.editarPartido = false;
          this.partidoEdicion = {};
          this._spinnerService.hideSpinner();
        },
        err => {
          console.log(err);
          this._spinnerService.hideSpinner();
        }
      );
      formData.controls.Nombre.touched = false;
      formData.controls.siglas.touched = false;
    } else {
      formData.controls.Nombre.touched = true;
      formData.controls.siglas.touched = true;
    }
  }

  confirmarEliminacionPartido(partido) {
    this.partidoAEliminar = partido;
  }

  eliminarPartido() {
    this._spinnerService.showSpinner();
    this._eliminarPartidoService
      .eliminarPartido(this.partidoAEliminar["idPartidoPolitico"])
      .subscribe(
        data => {
          this.obtenerParticipantes();

          if (data["codigo"] == "202") {
            this.toastr.warning(data["mensaje"]);
          } else {
            this.toastr.success(
              "El partido político fue eliminado exitosamente"
            );
          }
          this._spinnerService.hideSpinner();
        },
        err => {
          console.log(err);
          this._spinnerService.hideSpinner();
        }
      );
  }

  cancelarEdicionPartido() {
    this.editarPartido = false;
    this.partidoEdicion = {};
  }

  usuarioFecha() {
    var d = new Date();
    if (this.editarPartido) {
      this.partidoEdicion["fechaHora"] = d.getTime();
      this.partidoEdicion["usuario"] =
        this._authService.nombreUsuario || "carga.inicial";
    } else {
      this.consejeroEdicion["fechaHora"] = d.getTime();
      this.consejeroEdicion["usuario"] =
        this._authService.nombreUsuario || "carga.inicial";
    }
  }
}
