import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ObtenerSesionesService } from "../../servicios/obtenerSesiones/obtener-sesiones.service";
import { EliminarSesionService } from "../../servicios/eliminarSesion/eliminar-sesion.service";
import { SpinnerService } from "../../servicios/spinner/spinner.service";
import {  Router ,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-tabla-sesiones",
  templateUrl: "./tabla-sesiones.component.html",
  styleUrls: ["./tabla-sesiones.component.scss"]
})
export class TablaSesionesComponent implements OnInit {
  @Output() sesion = new EventEmitter();
  sesionSeleccionada;

  sesiones;
  fechas = [];
  idSesiones = [];
  filtroSesion = {
    numeroSesion: "",
    tipoSesion: "",
    fechaSesion: ""
  };

  public labels = {
    previousLabel: "",
    nextLabel: ""
  };
  sesionAEliminar = {};
  constructor(private _obtenerSesionesService: ObtenerSesionesService, private _eliminarSesionService:EliminarSesionService,
     private _spinnerService:SpinnerService, private router: Router, private toastr: ToastrService) {
    this.servicioObtenerSesiones();
  }

  ngOnInit() {}

  sesionOutput(id) {
    this.sesion.emit(id);
  }

  servicioObtenerSesiones() {
    
    this._obtenerSesionesService.obtenerSesiones(this.router.url );
  }

  eliminarSesion(){
    this._spinnerService.showSpinner();
     this._eliminarSesionService.eliminarSesion(this.sesionAEliminar['idSesion']).subscribe(data=>{
       if(data['codigo'] == "202"){
         this.toastr.warning(data['mensaje']);
       }else{
         this.toastr.success('¡La sesión fue eliminada exitosamente');
       }
       this.servicioObtenerSesiones();
     },err=>{
       console.log(err)
     })
  }

  confirmacionBorrar(sesion){
    this.sesionAEliminar = sesion;
  }
}
