import { Observable } from 'rxjs';
import { MenuService } from '../../servicios/menu/menu.service';
import { AuthService } from '../../servicios/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  menuVisible$: Observable<boolean>;

  public antes:string="";
  public despues:string="";
  public durante:string="";
  public reporte:string="";
  public CG:string="";
  public JGE:string="";
  public rolUsuario:string="";
  
  constructor(private _authService: AuthService, private _menuService: MenuService) {
    
  }

  

  ngOnInit() {
    // console.log("entre")
    this.rolUsuario = this._authService.rol ||"";
    this._menuService.verMenu(false)
  }
  
}
