import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AntesComponent } from './components/antes/antes.component';
import { DuranteComponent } from './components/durante/durante.component';
import { DespuesComponent } from './components/despues/despues.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { MenuCronometroComponent } from './components/menu-cronometro/menu-cronometro.component';
import { ConfiguracionSesionesComponent } from './components/configuracion-sesiones/configuracion-sesiones.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { CronometroComponent } from './components/cronometro/cronometro.component';


const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'antes', component: AntesComponent },
    { path: 'durante', component: DuranteComponent },
    { path: 'despues', component: DespuesComponent },
    { path: 'home', component: HomeComponent},
    { path: 'reportes', component: ReportesComponent},
    { path: 'configuracion-sesiones/:tipo', component: ConfiguracionSesionesComponent},
    { path: 'configuracion', component: ConfiguracionComponent},
    { path: 'menu-cronometro/:tipo', component: MenuCronometroComponent},
    { path: 'cronometro/:tipo/:id/:sesion', component: CronometroComponent},
    { path: '**', pathMatch: 'full', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
