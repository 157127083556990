import { MenuService } from '../../servicios/menu/menu.service';
import { Component, OnInit } from '@angular/core';
import { ObtenerSesionesService } from '../../servicios/obtenerSesiones/obtener-sesiones.service';
import { EdicionPuntosService } from '../../servicios/edicionPuntos/edicion-puntos.service';
import { SpinnerService } from '../../servicios/spinner/spinner.service';

@Component({
  selector: 'app-antes',
  templateUrl: './antes.component.html',
  styleUrls: ['./antes.component.scss']
})
export class AntesComponent implements OnInit {


  sesionAEditar = {};
  sesionRegistrada = false;
  public editar = false;

  constructor(private _menuService:MenuService, private _obtenerSesionesService:ObtenerSesionesService, private _edicionPuntosService:EdicionPuntosService, private _spinnerService: SpinnerService,) {
    this.limpiarSesion();

  }

  ngOnInit() {
    this._menuService.verMenu(true);
  }

  cancelarEdicion(){
    this.editar = false;
  }

  borrarSesion(posicion){
    console.log(posicion);

  }

  limpiarSesion(){

    this.sesionAEditar = {
      "sesion": {
        "idSesion": "",
        "tipoSesion": "ext",
        "numeroSesion": "",
        "fechaSesion": "",
        "fecha": "",
        "horas": "0",
        "minutos": "0",
        "usuario": "",
        "fechaUsuario": "",
        "visible": "1"
      },
      "puntosOrdenDia": [],
      "consejeros": [],
      "representantes": [],
      "poderLegislativo": [],
      "otros":[],
    };
    this._edicionPuntosService.puntos = [];
  }

  sesion(sesion) {
    this.getSesion(sesion);
  }

  eliminarPunto(){
    this._edicionPuntosService.eliminarPunto();
  }

  sesionGuardada(){
    this.sesionRegistrada = true;
    this.editar = false;
  }

  getSesion(idSesion){
    this._spinnerService.showSpinner();
    this._obtenerSesionesService.getSesion(idSesion).subscribe(data=>{
      this._edicionPuntosService.puntos = data['puntosOrdenDia'];
      let fechaHoras = data['sesion']['fechaSesion'].split(" ")[1]
      data['sesion']['fechaSesion'] = data['sesion']['fechaSesion'].split(" ")[0];
      data['sesion']['horas']= parseInt(fechaHoras.split(":")[0]);
      data['sesion']['minutos']= parseInt(fechaHoras.split(":")[1]);
      this.sesionAEditar = data;
      this.editar = true;
      this._spinnerService.hideSpinner();
    },err =>{
      console.log(err);
      this._spinnerService.hideSpinner();
    });
  }
}
