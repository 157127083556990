import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { ConstantesService } from '../constantes/constantes.service';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService  implements HttpInterceptor{

  constructor(private _constantesService: ConstantesService) { }

  intercept(req: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>>{
    const modifiedReq = req.clone({
      headers: req.headers.set('Authorization', `${this._constantesService.token}`),
    });
    return next.handle(modifiedReq)
  }
  
}
