import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../servicios/auth/auth.service';
import { SpinnerService } from '../../servicios/spinner/spinner.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    form: FormGroup;
    usuario:Object = {
        user: null,
        password:null
    }


    private formSubmitAttempt: boolean;
    verPassword = "password";

    constructor(
        private fb: FormBuilder,
        private _authService: AuthService,
        private _spinnerService:SpinnerService
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            userName: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    onSubmit(formData) {
        

        if((formData.controls.userName.valid && formData.controls.password.valid) && !this._spinnerService.banderaSpinner){
            
            this._authService.login(formData.value);
        }else{
            formData.controls.userName.touched = true;
            formData.controls.password.touched = true;
        }
    }

    cambiarTipoInput(){
        this.verPassword  = this.verPassword =="password" ? "text":"password";
    }
}
