import { Component, OnInit, Output, HostListener } from '@angular/core';
import { ObtenerSesionesService } from '../../servicios/obtenerSesiones/obtener-sesiones.service';
import { ObtenerRondasService } from '../../servicios/obtenerRondas/obtener-rondas.service';
import { ObtenerTipoParticipacionService } from '../../servicios/obtenerTipoParticipacion/obtener-tipo-participacion.service';
import { EdicionPuntosService } from '../../servicios/edicionPuntos/edicion-puntos.service';
import { ObtenerEstatusVotoService } from '../../servicios/obtenerEstatusVoto/obtener-estatus-voto.service';
import { GuardarTiemposSesionService } from '../../servicios/guardarTiemposSesion/guardar-tiempos-sesion.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../../servicios/auth/auth.service';
import { MenuService } from '../../servicios/menu/menu.service';
import { SpinnerService } from '../../servicios/spinner/spinner.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-durante',
  templateUrl: './durante.component.html',
  styleUrls: ['./durante.component.scss']
})
export class DuranteComponent implements OnInit {
  // consejeros = [{
  //   "cargo": "Consejero Presidente",
  //   "dtoPartidoPolitico": [],
  //   "fechaHora": 1572048998006,
  //   "idCargo": 1,
  //   "idParticipante": 1,
  //   "idPartidoPolitico": null,
  //   "idTratamiento": 1,
  //   "imagen": "consejero1.png",
  //   "nombre": "1",
  //   "primerApellido": "Córdova",
  //   "segundoApellido": "Vianello",
  //   "sha": "0e70ab64482ccdbed38b87e068853f78817041c6afd87091acca19937a01aceb",
  //   "tratamiento": "Doctor - Doctora",
  //   "usuario": "carga.inicial",
  //   "visible": 1
  // }, {
  //   "cargo": "Consejero Presidente",
  //   "dtoPartidoPolitico": [],
  //   "fechaHora": 1572048998006,
  //   "idCargo": 1,
  //   "idParticipante": 2,
  //   "idPartidoPolitico": null,
  //   "idTratamiento": 1,
  //   "imagen": "consejero1.png",
  //   "nombre": "2",
  //   "primerApellido": "Córdova",
  //   "segundoApellido": "Vianello",
  //   "sha": "2d08fe4d0ead046210eab2eedd7648b84196558f61fea227f799fb27db55e678",
  //   "tratamiento": "Doctor - Doctora",
  //   "usuario": "carga.inicial",
  //   "visible": 1
  // }];

  // representantes = [{
  //   "cargo": null,
  //   "fechaHora": 1572048998006,
  //   "idCargo": 6,
  //   "idParticipante": 11,
  //   "idPartidoPolitico": "1",
  //   "idTratamiento": 6,
  //   "imagen": "test.png",
  //   "sha": "386970fbc48393c2c75180e28a22ab3536dde0843440d9db942bde289d28aa25",
  //   "nombreParticipante": "Juan Antonio",
  //   "primerApellido": "Martín del Campo",
  //   "segundoApellido": "Martín del Campo",
  //   "tratamiento": null,
  //   "usuario": "carga.inicial",
  //   "visible": "1"
  // }];
  // otros = [{
  //   "cargo": null,
  //   "fechaHora": 1572048998006,
  //   "idCargo": 6,
  //   "idParticipante": 13,
  //   "idPartidoPolitico": "1",
  //   "idTratamiento": 6,
  //   "imagen": "test.png",
  //   "sha": "386970fbc48393c2c75180e28a22ab3536dde0843440d9db942bde289d28aa25",
  //   "nombreParticipante": "Juan Antonio",
  //   "primerApellido": "Martín del Campo",
  //   "segundoApellido": "Martín del Campo",
  //   "tratamiento": null,
  //   "usuario": "carga.inicial",
  //   "visible": "1"
  // }, {
  //   "cargo": null,
  //   "fechaHora": 1572048998006,
  //   "idCargo": 6,
  //   "idParticipante": 14,
  //   "idPartidoPolitico": "1",
  //   "idTratamiento": 6,
  //   "imagen": "test.png",
  //   "sha": "386970fbc48393c2c75180e28a22ab3536dde0843440d9db942bde289d28aa25",
  //   "nombreParticipante": "Otro2",
  //   "primerApellido": "Martín del Campo",
  //   "segundoApellido": "Martín del Campo",
  //   "tratamiento": null,
  //   "usuario": "carga.inicial",
  //   "visible": "1"
  // }];

  public sesionSeleccionada: boolean = false;
  public idSesionSeleccionada: number;
  public labels = {
    previousLabel: "",
    nextLabel: ""
  };
  public descripcion;
  public consejeros =[];
  public representantes =[];
  public representantesPartidos = [];
  public poderLegislativo =[];
  public poderLegislativoPartidos =[];
  public representantesPPyPl =[];
  public otros =[];
  public estatusVoto;
  public rondas;
  public ronda;
  public tipoParticipacionesPartidos;
  public tipoParticipaciones;
  public tipo;
  public idActivo = "";
  public participanteActivo = {};
  public idTipoParticipacion = "1";
  public idRonda = "1";
  public turno = 0;
  public partidoSeleccionado:number = 0;
  edicionPuntoIndex;
  miliSegundosCronometro = 0;
  segundosCronometro = 0;
  minutosCronometro = 0;
  miliSegundosCronometroSesion = 0;
  segundosCronometroSesion = 0;
  minutosCronometroSesion = 0;
  horasCronometroSesion = 0;
  tiempoTranscurridoPorParticipante = 0;
  tiempoTranscurridoSesion = "00:00:00";
  puntosSinPostergar = [];
  puntosSesionBandera: boolean = false;
  puntoATratar = {};
  puntoATratarIndice: number = 0;
  safeSrc: SafeResourceUrl;
  public ocultarInicio: boolean = false;
  public cronometroActivo: boolean = false;
  public cronometroSesionActivo: boolean = false;
  url = "https://www.youtube.com/embed/playlist?list=PLhUnWC-G2ynMfoL8FtN_-ynfy7kfleQE7";
  public tiemposParticipantes = [];

  constructor(private _menuService:MenuService, private _obtenerSesionesService: ObtenerSesionesService, private _edicionPuntosService: EdicionPuntosService, private sanitizer: DomSanitizer,
    private _obtenerTipoParticipacionService: ObtenerTipoParticipacionService, private _obtenerRondasService: ObtenerRondasService, private _spinnerService:SpinnerService,
    private _obtenerEstatusVotoService: ObtenerEstatusVotoService, private _authService: AuthService, private _guardarTiemposSesionService: GuardarTiemposSesionService, private toastr: ToastrService) {

    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.obtenerTipoParticipaciones();
    this.obtenerRondas();
    this.obtenerEstatusVoto();
  }

  ngOnInit() {
    this._menuService.verMenu(true);
  }
  //video
  buscarVideo(){
    let fileYoutube = '';
    if(this.url.includes("embed")){
      fileYoutube = this.url ;
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl( fileYoutube );
    }
    else if(this.url.includes(".be")){
      fileYoutube = this.url.split("/")[3];
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+fileYoutube );
    }
    else{
      fileYoutube = this.url.split("=")[1];
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+fileYoutube );
    }

  }

  obtenerEstatusVoto() {
    this._spinnerService.showSpinner();
    this._obtenerEstatusVotoService.getEstatusVoto().subscribe(data => {
      this.estatusVoto = data;
      this._spinnerService.hideSpinner();
    }, err => {
      this._spinnerService.hideSpinner();
    })
  }
  obtenerRondas() {
    this._spinnerService.showSpinner();
    this._obtenerRondasService.getRondas().subscribe(data => {
      this.rondas = data;
      this._spinnerService.hideSpinner();
    }, err => {
      this._spinnerService.hideSpinner();
    })
  }

  obtenerTipoParticipaciones() {
    this._spinnerService.showSpinner();
    this._obtenerTipoParticipacionService.getTipoParticipacion().subscribe(data => {
      this.tipoParticipaciones = data;
      let copia = JSON.stringify(data);
      this.tipoParticipacionesPartidos = JSON.parse(copia);
      this.tipoParticipacionesPartidos.splice(2,1);
      
      this._spinnerService.hideSpinner();
    }, err => {
      this._spinnerService.hideSpinner();
    })
  }
  sesion(sesion) {
    this.idSesionSeleccionada = sesion;

    this.getSesion(sesion);
  }

  cancelarEdicion() {
    this.ocultarInicio = false;
    this.sesionSeleccionada = false;
    this.cronometroSesionActivo = false;
    this.tiempoTranscurridoSesion = "00:00:00";
    this.participanteActivo ={};
    this.turno = 0;
    this.url = "https://www.youtube.com/embed/playlist?list=PLhUnWC-G2ynMfoL8FtN_-ynfy7kfleQE7";
    this.idActivo = "";
  }

  getSesion(idSesion) {
    this._spinnerService.showSpinner();
    this._obtenerSesionesService.getSesion(idSesion).subscribe(data => {
      if (!data["sesion"].tieneTiempoSesion){
        this.sesionSeleccionada = true;
        //console.log(this.sesionSeleccionada)
        this._edicionPuntosService.puntos = data['puntosOrdenDia'];
        this.consejeros = data["consejeros"].filter(consejero => consejero.visible == 1);
        //filtrando Partidos
        this.representantesPartidos = this.filtrarPartidos ( data["representantes"] );
        this.poderLegislativoPartidos = this.filtrarPartidos ( data["poderLegislativo"] ) ;
        //filtrar Partidos por representante
        this.representantes = this.ordenarCandidatosPorPartido(this.representantesPartidos,data["representantes"], this.poderLegislativoPartidos,data["poderLegislativo"] )
        // this.poderLegislativo = this.ordenarCandidatosPorPartido(this.poderLegislativoPartidos,data["poderLegislativo"] )

        this.otros = data["otros"];
        this._edicionPuntosService.puntos[0]['postergado'] = "Si";
        this.puntoATratar = this._edicionPuntosService.puntos[0];
      }else{
        this.toastr.warning("La sesión ya cuenta con tiempos registrados.");
        this.sesionSeleccionada = false;
      }


      this._spinnerService.hideSpinner();
    }, err => {
      this._spinnerService.hideSpinner();
    });
  }



  //filtramos los partidos para vista
  ordenarCandidatosPorPartido(representantesPartidos, representantesData, poderLegislativoPartidos, poderLegislativoData){
    let array = [];
    representantesPartidos.forEach( sha => {
      let jsonPartido = {
        "representantes" : [],
        "poderLegislativo" :[],
        "sha": ""
      }
      jsonPartido.representantes  = representantesData.filter( candidato => candidato.sha == sha);
      jsonPartido.poderLegislativo = poderLegislativoData.filter( pl => pl.sha == sha)
      jsonPartido.sha = sha;
      array.push(jsonPartido);
    });

   poderLegislativoPartidos.forEach(sha => {
      let index = representantesPartidos.findIndex( partido => partido == sha)
      if( index == -1){
        let jsonPartido = {
          "representantes" : [],
          "poderLegislativo" :[],
          "sha": ""
        }
        jsonPartido.representantes  = representantesData.filter( candidato => candidato.sha == sha);
        jsonPartido.poderLegislativo = poderLegislativoData.filter( pl => pl.sha == sha)
        jsonPartido.sha = sha;
        array.push(jsonPartido);
      }

    })
    return array
  }
//obtener Sha de img por partido
  filtrarPartidos(data){
      let array = [];
      data.forEach(partido => {
        if ( !array.includes( partido.sha ) ) {
            array.push(partido.sha );
          }
      })
      return array;
  }
  //edicion Puntos
  editarPunto(puntoAEditar) {
    this.edicionPuntoIndex = this._edicionPuntosService.puntos.findIndex( punto =>{
      return punto.idPuntoOrden == puntoAEditar.idPuntoOrden;
    });

    this.descripcion = puntoAEditar.descripcion;
  }
  //actualizarPunto
  actualizarPunto() {
    this._edicionPuntosService.puntos[this.edicionPuntoIndex].descripcion = this.descripcion
  }

  postergarPunto(puntoAPostergar) {

    let indexPunto = this._edicionPuntosService.puntos.findIndex( punto =>{
      return punto.idPuntoOrden == puntoAPostergar.idPuntoOrden;
    });

    if (this._edicionPuntosService.puntos[indexPunto]['postergado'] == undefined || this._edicionPuntosService.puntos[indexPunto]['postergado'] == "No") {
      this._edicionPuntosService.puntos[indexPunto]['postergado'] = "Si"
    } else {
      this._edicionPuntosService.puntos[indexPunto]['postergado'] = "No"
    }

  }


  

  //participaciones Consejero presidente y secretario 
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    
    switch(event.key){
      case "1":
        this.participacion(this.consejeros[0]);
        break;
      case "2":
        this.participacion(this.consejeros[1]);
        break;

    }
    
  }

  //participaciones Partidos o representantes
  participacionPartidos(participante){

    if(this.participanteActivo['idPartidoPolitico'] != participante['idPartidoPolitico'] ){
      this.participacion(participante);
    }else{
      this.participanteActivo = participante;
      this.idActivo = participante.idParticipante
    }

  }

//participaciones Partidos o representantes
  participacion(participante ) {

    this.participanteActivo = participante;
    //iniciamos crono
    if (this.turno == 0) {
      this.cronometroSesionActivo = true;
      this.cronometroSesion();
    }

      //prguntamos si cambio el participante
      if (this.cronometroActivo && (this.idActivo != participante.idParticipante || this.idActivo == participante.idParticipante)) {

          this.cronometroActivo = false;
          this.asignarTiempoTranscurrido();
          
          if (this.idActivo != participante.idParticipante) {
  
            setTimeout(() => {
              this.idActivo = participante.idParticipante
              this.cronometroActivo = true;
              this.turno++;
              this.scroll();
              this.cronometro();
            }, 100);
          }
      } else {
        this.idActivo = participante.idParticipante;
        this.cronometroActivo = true;
        this.turno++;
        this.scroll();
        this.cronometro();
      }
  }

  scroll(){
    let el = document.getElementById('frame');
    el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

  //Puntos sesion
  obtenerPuntos() {
    this.puntosSinPostergar = [];
    this.puntoATratar = {};
    this.puntosSinPostergar = this._edicionPuntosService.puntos.filter(punto => {
      return !punto.hasOwnProperty('postergado') || punto.postergado == "No"
    })
    if (this.puntosSinPostergar.length > 0) {
      this.puntosSesionBandera = true;
      this.puntoATratarIndice = 0;
      this.puntoATratar = this.puntosSinPostergar[this.puntoATratarIndice]
      this.ocultarInicio = true;
      if (this.cronometroActivo) {
        this.asignarTiempoTranscurrido();
      }
    } else {
      this.puntosSesionBandera = false;
    }
    
  }

  siguientePunto() {
    if (this.cronometroActivo) {
      this.asignarTiempoTranscurrido();
    }
    let index = this._edicionPuntosService.puntos.findIndex(punto => punto['idPuntoOrden'] == this.puntoATratar['idPuntoOrden'])
    this._edicionPuntosService.puntos[index]['idEstatusVoto'] = parseInt(this.puntosSinPostergar[this.puntoATratarIndice]['idEstatusVoto']);
    this.puntoATratarIndice++;
    this.puntoATratar = this.puntosSinPostergar[this.puntoATratarIndice];

  }
  //Guardar sesion
  guardarTiempos() {
    this._spinnerService.showSpinner();
    let index = this._edicionPuntosService.puntos.findIndex(punto => punto['idPuntoOrden'] == this.puntoATratar['idPuntoOrden'])
    this._edicionPuntosService.puntos[index]['idEstatusVoto'] = parseInt(this.puntosSinPostergar[this.puntoATratarIndice]['idEstatusVoto']);
    let segundosTiemoSesion = this.horasCronometroSesion * 360 + this.minutosCronometroSesion *60 + this.segundosCronometroSesion;
    let d = new Date();
    this.cronometroSesionActivo = false;
    if (this.cronometroActivo) {
      this.asignarTiempoTranscurrido();
    }
    let tiemposSesion = {
      "idSesion" : this.idSesionSeleccionada,
      "usuario" : this._authService.nombreUsuario,
      "fechaHora" : d.getTime(),
      "puntosSesion" : this._edicionPuntosService.puntos,
      "tiemposSesion" : this.tiemposParticipantes,
      "tiempoSesion" : segundosTiemoSesion,
      "tiempoInicio" : 0
      // "tiempoSesion" : this.tiempoTranscurridoSesion  /*sacarSegundos*/
      // "tiempoInicio" : this.tiempoTranscurridoSesion
    }
    console.log(JSON.stringify(tiemposSesion))
    this._guardarTiemposSesionService.postTiemposSesion(tiemposSesion).subscribe(data => {
      this.sesionSeleccionada = false;
      this.tiempoTranscurridoSesion = "00:00:00";
      this.turno = 0;
      this._spinnerService.hideSpinner();
    }, err => {
      console.log(err);
      this._spinnerService.hideSpinner();
    })
  }
  //cronometro Sesion
  cronometroSesion() {

    if (this.cronometroSesionActivo) {

      this.miliSegundosCronometroSesion += 100;

      if (this.miliSegundosCronometroSesion == 1000) {
        this.miliSegundosCronometroSesion = 0;
        this.segundosCronometroSesion++;
        //incrementamos Minutos
        if (this.segundosCronometroSesion == 60) {
          this.minutosCronometroSesion++;
          this.segundosCronometroSesion = 0;
          //incrementamos horas
          if (this.minutosCronometroSesion == 60) {
            this.minutosCronometroSesion = 0;
            this.segundosCronometroSesion = 0;
            this.horasCronometroSesion++;
          }
        }

      }

      this.tiempoTranscurridoSesion = `${this.horasCronometroSesion < 10 ? "0" + this.horasCronometroSesion : this.horasCronometroSesion}:${this.minutosCronometroSesion < 10 ? "0" + this.minutosCronometroSesion : this.minutosCronometroSesion}:${this.segundosCronometroSesion < 10 ? "0" + this.segundosCronometroSesion : this.segundosCronometroSesion}`;

      let self = this;
      setTimeout(function() {
        self.cronometroSesion();
      }, 100);
    }

  }
  //cronometro Participaciones
  cronometro() {
    if (this.cronometroActivo) {

      this.miliSegundosCronometro += 100;

      if (this.miliSegundosCronometro == 1000) {
        this.miliSegundosCronometro = 0;
        this.segundosCronometro++;
        if (this.segundosCronometro == 60) {
          this.minutosCronometro++;
          this.segundosCronometro = 0;
        }

      }

      this.tiempoTranscurridoPorParticipante = this.minutosCronometro * 60 + this.segundosCronometro;

      let self = this;
      setTimeout(function() {
        self.cronometro();
      }, 100);
    }
  }

  asignarTiempoTranscurrido() {
    this.cronometroActivo = false;

    let participante = {
      "tiempo": this.tiempoTranscurridoPorParticipante,
      "turno": this.turno,
      "idRonda": this.idRonda,
      "idTipoParticipacion": this.idTipoParticipacion,
      "idPuntoOrden": this.puntoATratar['idPuntoOrden'],
      //"estatusVoto": null, //preguntar a Pau
      "idParticipante": this.idActivo
      //"usuario"
      //"fechaHora"
    }

    this.minutosCronometro = 0;
    this.segundosCronometro = 0;
    this.tiemposParticipantes.push(participante);
    console.log(this.tiemposParticipantes)
  }

}
