import { Observable } from 'rxjs';
import { AuthService } from '../../../servicios/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie-de-pagina',
  templateUrl: './pie-de-pagina.component.html',
  styleUrls: ['./pie-de-pagina.component.scss']
})
export class PieDePaginaComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;
  constructor(private authService: AuthService) { }
  
  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  onLogout() {
    this.authService.logout();
  }

}
