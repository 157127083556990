import { Component, OnInit, ElementRef } from '@angular/core';
import { MultiWindowService, Message } from 'ngx-multi-window';
import { ActivatedRoute } from "@angular/router";
import { TiemposService } from "../../servicios/tiempos/tiempos.service";
import {SpinnerService } from "../../servicios/spinner/spinner.service"
@Component({
  selector: 'app-cronometro',
  templateUrl: './cronometro.component.html',
  styleUrls: ['./cronometro.component.scss']
})
export class CronometroComponent implements OnInit {
  
  public tiempo = "00 : 00";
  public sesion ;
  public banderaPause = false;
  public tiempoAgotado = false;
  public minutos = 0;
  public segundos = 0;
  public horas = 0;
  public ventanaPadreID;
  public tipoSesion;
  public tiempoCalculado = "00 : 00"
  public tipo = "C";
  public banderaContadores = false;
  public idTimer ;
  

  constructor(private multiWindowService: MultiWindowService, private route: ActivatedRoute, private _tiemposService:TiemposService, private elementRef: ElementRef, private _spinnerService:SpinnerService) {
    this._spinnerService.showSpinner();
    this.route.paramMap.subscribe(params => {
      this.ventanaPadreID = params.get("id");
      this.sesion = params.get("sesion");
      this.tipoSesion = params.get("tipo");
      switch( this.tipoSesion ){
        case "CG":
          this.tipoSesion = `Consejo General`
        break;
        case "JGE":
          this.tipoSesion = `Junta General Ejecutiva`
        break;
      }
      //obtenemos el id de nuestra ventana padre, para comenzar la comunicación
      this.enviarMensaje(this.ventanaPadreID , "Conectando");
      
    })

  //Aperturamos conexión para la escucha de mensajes entrantes
    this.multiWindowService.onMessage().subscribe((value: Message) => {
      console.log('Received a message from ' + value.senderId + ' -- ' + value.data);
      this._spinnerService.hideSpinner();
      // if(parseInt(this.tiempo.split(":")[0]) == 0 && parseInt(this.tiempo.split(":")[1]) <= 10){
        //   this.tiempoAgotado = true;
        // }else{
          //   this.tiempoAgotado = false;
          // }
       if(this.banderaContadores) {
        this.pausar();
       }  
      console.log(value.data)
      this.sesion = value.data.sesion;
      this.tiempo = value.data.tiempo;
      this.tipo = value.data.tipo
      this.convierteMinutosSegundos(this.tiempo);
      this.convierteMinutosString();

    });

  }

  ngOnInit() {

  }

  ngAfterViewInit(){
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#302F2F';
 }

  iniciar(){
    this.banderaContadores = true;
    if(this.tipo == "C" ){ 
      this.cronometro();
    }else{
      if (this.minutos == 0 && this.segundos == 0){
        this.convierteMinutosSegundos(this.tiempo)
        
      }
      this.temporizador()
    }
  }

  pausar(){
    this.banderaContadores = false; 
    clearTimeout(this.idTimer)
  }

  reiniciar(){
    this.pausar()
    clearTimeout(this.idTimer)
    if (this.tipo == "T"){
      this.convierteMinutosSegundos(this.tiempo);
      this.convierteMinutosString();
    }else{
      this.minutos = 0;
      this.segundos = 0;
      this.horas = 0;
      this.tiempoCalculado = "00 : 00";
    }
  }

  cronometro(){
    console.log(this.banderaContadores)
    if( this.banderaContadores ){
      
      if (this.segundos == 60) {
        this.minutos++;
        if (this.minutos == 60) {
          this.minutos = 0;
          this.segundos = 0;
          this.horas ++;
          if(this.horas == 24){
            this.horas = 0;
          }
        }
        this.segundos = 0;
        this.tiempoCalculado = `${
          this.minutos < 10
            ? "0" + this.minutos
            : this.minutos
        } : ${
          this.segundos < 10
            ? "0" + this.segundos
            : this.segundos
        }`;
      } else {
        if (this.minutos == 60) {
          this.minutos = 0;
          this.segundos = 0;
          this.horas ++;
          if(this.horas == 24){
            this.horas = 0;
          }
        } else {
          this.convierteMinutosString();
          
        }
        
      }
      
      let self = this;
      this.idTimer = setTimeout(function() {
        
        self.segundos ++;
        clearTimeout(self.idTimer)
        self.cronometro();
      }, 1000);
    }
  }

  temporizador(){
    if (this.banderaContadores) {
        this.segundos--;
        if (this.segundos == -1) {
          this.minutos--;
          this.segundos = 60;
        } else {
          if (this.minutos == -1) {
            this.minutos = -1;
            this.segundos = -1;
            this.tiempoCalculado  = `00 : 00`;
            this.tiempoAgotado = true;

          } else {
            this.convierteMinutosString();
          }
        }
      }
    let self = this;
    this.idTimer = setTimeout(function() {
      clearTimeout(self.idTimer); 
      self.temporizador();
    }, 1000);  
  }

  almacenar(){
    this.pausar()
    this.enviarMensaje(this.ventanaPadreID, this.minutos *60 + this.segundos)
}

  enviarMensaje( id, mensaje){
    this.multiWindowService.sendMessage(id , 'customEvent', mensaje ).subscribe(
      () => {
        console.log('Message sended, to ' + id);
      },
      (error) => {
        console.log('Message sending failed, error: ' + error);
      },
      () => {
        console.log('Message successfully delivered');
      });
  }
  convierteMinutosSegundos(tiempo){
    this.minutos = Math.floor(tiempo/60);

        this.segundos = tiempo % 60
  }
  convierteMinutosString(){
    this.tiempoCalculado = `${
      this.minutos < 10
        ? "0" + this.minutos
        : this.minutos
    } : ${
      this.segundos < 10
        ? "0" + this.segundos
        : this.segundos
    }`;
  }
}
