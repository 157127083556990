import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { IntegrantesService } from "../../servicios/integrantes/integrantes.service";
import { ObtenerCargosService } from "../../servicios/obtenerCargos/obtener-cargos.service";
import { ObtenerSesionesService } from "../../servicios/obtenerSesiones/obtener-sesiones.service";
import { ObtenerTratamientosService } from "../../servicios/obtenerTratamientos/obtener-tratamientos.service";
import { GuardarSesionService } from "../../servicios/guardaSesion/guardar-sesion.service";
import { EditarSesionService } from "../../servicios/editarSesion/editar-sesion.service";
import { EdicionPuntosService } from "../../servicios/edicionPuntos/edicion-puntos.service";
import { SpinnerService } from "../../servicios/spinner/spinner.service";
import { AuthService } from "../../servicios/auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-captura-sesion",
  templateUrl: "./captura-sesion.component.html",
  styleUrls: ["./captura-sesion.component.scss"]
})
export class CapturaSesionComponent implements OnInit {
  @Output() sesionGuardada = new EventEmitter();
  @Input() editar: boolean;
  @Input() jsonSesion = {
    sesion: {
      idSesion: "",
      tipoSesion: "ext",
      numeroSesion: "",
      fechaSesion: "",
      fecha: "",
      horas: "0",
      minutos: "0",
      usuario: "",
      fechaUsuario: "",
      visible: "1",
      tieneTiempoSesion: false
    },
    puntosOrdenDia: [],
    consejeros: [],
    poderLegislativo: [],
    representantes: [],
    otros: []
  };
  numerosHoras;
  numerosMinutos;
  clave = "1";
  punto = "";
  banderaEditarPunto = false;
  banderaTratamiento = false;
  banderaCargo = false;
  banderaParticipantes = false;
  tratamientos;
  cargos;
  public tipoSesion = this.jsonSesion.sesion["tipo"];
  public seleccionarPartido: number = 0;
  public representantesPartidos = [];
  public otro = {
    nombreParticipante: "",
    primerApellido: "",
    segundoApellido: "",
    tratamiento: "",
    idCargo: "",
    cargo: "",
    usuario: "",
    fechaUsuario: ""
  };

  consejeros = [];

  partidos = [];
  poderLegislativo = [];
  puntoAEliminar = {};

  // Validaciones
  validaConsejerosElectorales: boolean = false;
  validaIntegrantesPartidos: boolean = false;
  puntoVacio: boolean = false;

  constructor(
    private _integrantesService: IntegrantesService,
    private _guardarSesionService: GuardarSesionService,
    private _editarSesionServiceprivate: EditarSesionService,
    private _edicionPuntosService: EdicionPuntosService,
    private _authService: AuthService,
    private _obtenerSesionesService: ObtenerSesionesService,
    private toastr: ToastrService,
    private _obtenerCargosService: ObtenerCargosService,
    private _obtenerTratamientosServicev: ObtenerTratamientosService,
    private _spinnerService: SpinnerService,
    private router: Router
  ) {
    this._spinnerService.showSpinner();
    this.numerosMinutos = Array(60)
      .fill(0)
      .map((x, i) => i);
    this.numerosHoras = Array(24)
      .fill(0)
      .map((x, i) => i);
    // this._edicionPuntosService.puntos = this.jsonSesion.puntosOrdenDia;
  }

  ngOnInit() {
    this.obtenerConsejeros();
    this.obtenerTratamientos();
    this.obtenerCargos();
  }
  ngAfterViewInit() {
    if (this.jsonSesion.consejeros.length > 0) {
      this._spinnerService.showSpinner();
      //Iteramos consejeros, para saber cual esta activo
      this.jsonSesion.consejeros.forEach(consejero => {
        if (consejero.visible == "1") {
          let index = this.consejeros.findIndex(consejeroEnSesion => {
            return (
              consejeroEnSesion["idParticipante"] == consejero["idParticipante"]
            );
          });
          if (index != -1) {
            this.consejeros[index].visible = "1";
          }
        }
      });
    }
    if (this.jsonSesion.representantes.length > 0) {
      this.jsonSesion.representantes.forEach(representanteEnSesion => {
        //iteramos los partidos para buscar el indice del representante en sesion
        this.partidos.forEach(partido => {
          //comparamos el representante en sesion con los representantes de cada partido
          let representanteEncontrado = partido.representantes.findIndex(
            representante => {
              return (
                representante.idParticipante ==
                representanteEnSesion.idParticipante
              );
            }
          );
          if (representanteEncontrado != -1) {
            partido.representantes[representanteEncontrado].visible = "1";
          }
        });
      });
    }
    if (this.jsonSesion.poderLegislativo.length > 0) {
      this.jsonSesion.poderLegislativo.forEach(representanteEnSesion => {
        //iteramos los partidos para buscar el indice del representante en sesion
        this.poderLegislativo.forEach(partido => {
          //comparamos el representante en sesion con los representantes de cada partido
          let representanteEncontrado = partido.representantes.findIndex(
            representante => {
              return (
                representante.idParticipante ==
                representanteEnSesion.idParticipante
              );
            }
          );
          if (representanteEncontrado != -1) {
            partido.representantes[representanteEncontrado].visible = "1";
          }
        });
      });
    }
    this._spinnerService.hideSpinner();
    
  }

  validaStep1() {
    // Comenzamos con la Validación decuantos consejeros tenemos seleccionados
    let contadorVisibles: any = 0;
    for (let valConsejeros of this.consejeros) {
      // verificamos la propiedad visible y si es igual a 1 aumentamos la variable contadorVisible
      if (valConsejeros.visible == 1) {
        contadorVisibles = contadorVisibles + 1;
      }
    }
    // verificamos la variable contadorVisibles y si es mayor a 7 quitamos el mensaje en pantalla
    if (contadorVisibles >= 7) {
      // Si la variable es mayor a 7 quitamos en error en pantalla y dejamos pasar al step 2
      this.validaConsejerosElectorales = false;
      let element: HTMLElement = document.getElementById(
        "siguienteStep1"
      ) as HTMLElement;
      element.click();
    } else {
      // Si la variable es menor a 7 mostramos error en pantallae impedimos pasar al step2
      this.validaConsejerosElectorales = true;
    }
  }

  validaStep2() {
    let numpartidos = this.partidos.length;
    let contadorVisible: any = 0;
    for (let valPartidos of this.partidos) {
      // if(valPartidos.representantes[0].visible == null && valPartidos.representantes[1].visible == null){
      //     console.log("selectPartido"+valPartidos.idPartidoPolitico)
      //     let element = document.getElementById("selectPartido"+valPartidos.idPartidoPolitico);
      //     element.classList.add('invalidPartidosPoliticos')
      // }
      for (let valRepresentantes of valPartidos.representantes) {
        if (valRepresentantes.visible == null) {
          let element = document.getElementById(
            "selectPartido" + valPartidos.idPartidoPolitico
          );
          element.classList.add("invalidPartidosPoliticos");
        }
      }
      for (let valRepresentantes of valPartidos.representantes) {
        if (valRepresentantes.visible == 1) {
          contadorVisible = contadorVisible + 1;
        }
      }
    }


  }
  editarPuntoTieneTiempoSesion(){
    let puntoOrdenDia = this._edicionPuntosService.puntos.findIndex(
      punto => {
        return punto["claveOrden"] == this.clave;
      }
    );
    if (puntoOrdenDia == -1) {
      let punto = {
        idPuntoOrden: "",
        claveOrden: this.clave,
        visible: "1",
        descripcion: this.punto,
        usuario: "",
        fechaUsuario: ""
      };
      this.clave = "";
      this.punto = "";
      this._edicionPuntosService.puntos.push(punto);
    } else {
      this._edicionPuntosService.puntos[puntoOrdenDia][
        "claveOrden"
      ] = this.clave;
      this._edicionPuntosService.puntos[puntoOrdenDia][
        "descripcion"
      ] = this.punto;
    }
  }
  agregarPunto(formData) {
    
    if (formData.controls.clavePunto.valid && formData.controls.punto.valid) {
      if (this.clave != "" && this.punto != "") {
        let puntoOrdenDia = this._edicionPuntosService.puntos.findIndex(
          punto => {
            return punto["claveOrden"] == this.clave;
          }
        );
        if (puntoOrdenDia == -1) {
          let punto = {
            idPuntoOrden: "",
            claveOrden: this.clave,
            visible: "1",
            descripcion: this.punto,
            usuario: "",
            fechaUsuario: ""
          };
          this.clave = "";
          this.punto = "";
          this._edicionPuntosService.puntos.push(punto);
        } else {
          this._edicionPuntosService.puntos[puntoOrdenDia][
            "claveOrden"
          ] = this.clave;
          this._edicionPuntosService.puntos[puntoOrdenDia][
            "descripcion"
          ] = this.punto;
        }
      }
      formData.controls.clavePunto.touched = false;
      formData.controls.punto.touched = false;
      this.puntoVacio = false;
    } else {
      formData.controls.clavePunto.touched = true;
      formData.controls.punto.touched = true;
    }
  }

  editarPunto(id) {
    this.punto = this._edicionPuntosService["puntos"][id]["descripcion"];
    this.clave = this._edicionPuntosService["puntos"][id]["claveOrden"];
  }

  confirmacionBorrar(punto) {
    this._edicionPuntosService.puntoPorEliminar = punto;
  }

  obtenerConsejeros() {
    this._spinnerService.showSpinner();
    this._integrantesService.getParticipantes().subscribe(
      data => {
        this.consejeros = data["consejeros"];
        this.seleccionarTodos()

        this.partidos = data["partidos"].filter(
          partido => partido.representantes.length > 0
        );
        this.partidos.forEach(partido=>{
          partido.representantes.forEach(representante => representante.visible = 1);
        })
       
        this.poderLegislativo = data['poderLegislativo']

        this.poderLegislativo.forEach(partido=>{
          partido.representantes.forEach(representante => representante.visible = 1);
        })
        
        this.banderaParticipantes = true;
        this._spinnerService.hideSpinner();
      },
      err => {
        this._spinnerService.hideSpinner();
        console.log(err);
      }
    );
  }

  obtenerCargos() {
    this._spinnerService.showSpinner();
    this._obtenerCargosService.getCargos().subscribe(
      data => {
        this.banderaCargo = true;
        if (Array.isArray(data)) {
          this.cargos = data.filter(cargo => cargo.idCargo == 5 || cargo.idCargo == 7);
        }

        this._spinnerService.hideSpinner();
      },
      err => {
        console.log(err);
        this._spinnerService.hideSpinner();
      }
    );
  }

  seleccionarCargo(id) {
    var index = this.cargos.findIndex(cargo => cargo.idCargo == id);
    this.otro.cargo = this.cargos[index].nombreCargo;
  }

  obtenerTratamientos() {
    this._spinnerService.showSpinner();
    this._obtenerTratamientosServicev.getTratamientos().subscribe(
      data => {
        this.banderaTratamiento = true;
        this.tratamientos = data;
        this._spinnerService.hideSpinner();
      },
      err => {
        console.log(err);
        this._spinnerService.hideSpinner();
      }
    );
  }

  agregarRemoverConsejero(id) {
    // Cambiamos la propiedad visible del Consejero seleccionado
    this.consejeros[id].visible == "1"
      ? (this.consejeros[id].visible = "0")
      : (this.consejeros[id].visible = "1");


    // Comenzamos con la Validación decuantos consejeros tenemos seleccionados
    let contadorVisibles: any = 0;
    // Recorremos el array consejeros
    for (let valConsejeros of this.consejeros) {
      // verificamos la propiedad visible y si es igual a 1 aumentamos la variable contadorVisible
      if (valConsejeros.visible == 1) {
        contadorVisibles = contadorVisibles + 1;
      }
    }
    // verificamos la variable contadorVisibles y si es mayor a 7 quitamos el mensaje en pantalla
    if (contadorVisibles >= 7) {
      this.validaConsejerosElectorales = false;
    } else {
      this.validaConsejerosElectorales = true;
    }
  }

  seleccionarTodos() {
    this.validaConsejerosElectorales = false;
    this.consejeros.forEach(consejero => {
      consejero.visible = "1";
    });
  }

  seleccionarRepresentantePP(partido, posicionRepresentante) {
    if(this.partidos[partido].representantes[posicionRepresentante]['visible'] == null){
      this.partidos[partido].representantes[posicionRepresentante]['visible'] = "1"
    } else{
        this.partidos[partido].representantes[posicionRepresentante]['visible'] == "1" ? this.partidos[partido].representantes[posicionRepresentante]['visible'] = "0" : this.partidos[partido].representantes[posicionRepresentante]['visible'] = "1";
    }
  }
  seleccionarRepresentantePL(partido, posicionRepresentante) {
    if(this.poderLegislativo[partido].representantes[posicionRepresentante]['visible'] == null){
      this.poderLegislativo[partido].representantes[posicionRepresentante]['visible'] = "1"
    } else{
        this.poderLegislativo[partido].representantes[posicionRepresentante]['visible'] == "1" ? this.poderLegislativo[partido].representantes[posicionRepresentante]['visible'] = "0" : this.poderLegislativo[partido].representantes[posicionRepresentante]['visible'] = "1";
    }

  }

  agregarOtroParticipante(participante, formData) {
    if (
      formData.controls.tratamiento.valid &&
      formData.controls.nombreParticipante.valid &&
      formData.controls.primerApellido.valid &&
      formData.controls.cargo.valid
    ) {
      this.jsonSesion.otros.push(participante);
      this.otro = {
        nombreParticipante: "",
        primerApellido: "",
        segundoApellido: "",
        tratamiento: "",
        cargo: "",
        idCargo: "",
        usuario: "",
        fechaUsuario: ""
      };
      formData.controls.tratamiento.touched = false;
      formData.controls.nombreParticipante.touched = false;
      formData.controls.primerApellido.touched = false;
      formData.controls.cargo.touched = false;
    } else {
      formData.controls.tratamiento.touched = true;
      formData.controls.nombreParticipante.touched = true;
      formData.controls.primerApellido.touched = true;
      formData.controls.cargo.touched = true;
    }
  }

  guardarSesion(formData) {
    if (
      formData.controls.tipo.valid &&
      formData.controls.idSesion.valid &&
      formData.controls.dp.valid &&
      formData.controls.hora.valid &&
      formData.controls.minutos.valid
    ) {
      if (this._edicionPuntosService.puntos.length >= 1) {
        this.jsonSesion["puntosOrdenDia"] = this._edicionPuntosService.puntos;
        let puntoInicial = {
          idPuntoOrden: "",
          claveOrden: "0",
          visible: "1",
          descripcion: "Inicio",
          usuario: "",
          fechaUsuario: ""
        };

        this.jsonSesion["puntosOrdenDia"].unshift(puntoInicial);

        this._spinnerService.showSpinner();
        this.jsonSesion.sesion.usuario =
          this._authService.nombreUsuario || "prueba.ine";
        this.jsonSesion.sesion.fechaUsuario = `${this.formatDate()}`;
        this.jsonSesion.sesion.fechaSesion = `${
          this.jsonSesion.sesion.fechaSesion
        } ${
          parseInt(this.jsonSesion.sesion.horas) < 10
            ? "0" + this.jsonSesion.sesion.horas
            : this.jsonSesion.sesion.horas
        }:${
          parseInt(this.jsonSesion.sesion.minutos) < 10
            ? "0" + this.jsonSesion.sesion.minutos
            : this.jsonSesion.sesion.minutos
        }:00`;
        this.jsonSesion.puntosOrdenDia.forEach(puntos => {
          puntos["usuario"] = this.jsonSesion.sesion.usuario;
          puntos["fechaUsuario"] = this.jsonSesion.sesion.fechaUsuario;
        });

        this.consejeros.forEach(participante => {
          this.jsonSesion.consejeros.push(participante);
        });

        this.partidos.forEach(partido => {
          partido.representantes.forEach(representante => {
            if (representante.visible == "1") {
              this.jsonSesion.representantes.push(representante);
            }
          });
        });

        this.poderLegislativo.forEach(partido => {
          partido.representantes.forEach(representante => {
            if (representante.visible == "1") {
              this.jsonSesion['poderLegislativo'].push(representante);
            }
          });
        });

        this.jsonSesion.consejeros.forEach(participante => {
          participante["usuario"] = this.jsonSesion.sesion.usuario;
          participante["fechaUsuario"] = this.jsonSesion.sesion.fechaUsuario;
        });

        this.jsonSesion.otros.forEach(otro => {
          otro["usuario"] = this.jsonSesion.sesion.usuario;
          otro["fechaUsuario"] = this.jsonSesion.sesion.fechaUsuario;
        });
        this._guardarSesionService.postSesion(this.jsonSesion).subscribe(
          data => {
            this.toastr.success("¡La sesión ha sido guardada exitosamente!");
            this.limpiarDatos();
            this._spinnerService.hideSpinner();
          },
          err => {
            console.log(err);
            this._spinnerService.hideSpinner();
          }
        );
      } else {
        this.puntoVacio = true;
        this.toastr.warning("Se necesita agregar al menos un punto");
      }
    } else {
      formData.controls.tipo.touched = true;
      formData.controls.idSesion.touched = true;
      formData.controls.dp.touched = true;
      formData.controls.hora.touched = true;
      formData.controls.minutos.touched = true;
    }
  }

  editarSesion() {
    if (this._edicionPuntosService.puntos.length >= 1) {
      this.jsonSesion["puntosOrdenDia"] = this._edicionPuntosService.puntos;
      this._spinnerService.showSpinner();
      this.jsonSesion.consejeros = [];
      this.jsonSesion.representantes = [];
      this.jsonSesion.poderLegislativo = [];

      this.jsonSesion.sesion.usuario =
        this._authService.nombreUsuario || "prueba.ine";
      this.jsonSesion.sesion.fechaUsuario = `${this.formatDate()}`;
      this.jsonSesion.sesion.fechaSesion = `${
        this.jsonSesion.sesion.fechaSesion
      } ${
        parseInt(this.jsonSesion.sesion.horas) < 10
          ? "0" + this.jsonSesion.sesion.horas
          : this.jsonSesion.sesion.horas
      }:${
        parseInt(this.jsonSesion.sesion.minutos) < 10
          ? "0" + this.jsonSesion.sesion.minutos
          : this.jsonSesion.sesion.minutos
      }:00`;

      this.jsonSesion.puntosOrdenDia.forEach(puntos => {
        puntos["usuario"] = this.jsonSesion.sesion.usuario;
        puntos["fechaUsuario"] = this.jsonSesion.sesion.fechaUsuario;
      });

      this.consejeros.forEach(participante => {
        this.jsonSesion.consejeros.push(participante);
      });

      this.partidos.forEach(partido => {
        partido.representantes.forEach(representante => {
          if (representante.visible == "1") {
            this.jsonSesion.representantes.push(representante);
          }
        });
      });

      this.poderLegislativo.forEach(partido => {

        partido.representantes.forEach(representante => {
          if (representante.visible == "1") {

            this.jsonSesion['poderLegislativo'].push(representante);
          }
        });

      });


      this.jsonSesion.consejeros.forEach(participante => {
        participante["usuario"] = this.jsonSesion.sesion.usuario;
        participante["fechaUsuario"] = this.jsonSesion.sesion.fechaUsuario;
      });

      this.jsonSesion.otros.forEach(otro => {
        otro["usuario"] = this.jsonSesion.sesion.usuario;
        otro["fechaUsuario"] = this.jsonSesion.sesion.fechaUsuario;
      });

      this._editarSesionServiceprivate
        .actualizarSesion(this.jsonSesion)
        .subscribe(
          data => {
            this.toastr.success(
              "¡Los datos han sido actualizados correctamente!"
            );
            this.limpiarDatos();
            this._spinnerService.hideSpinner();
          },
          err => {
            this._spinnerService.hideSpinner();
            console.log(err);
          }
        );
    } else {
      this.puntoVacio = true;
      this.toastr.warning("Se necesita agregar al menos un punto");
    }
  }

  limpiarDatos() {
    this.jsonSesion = {
      sesion: {
        idSesion: "",
        tipoSesion: "ext",
        numeroSesion: "",
        fechaSesion: "",
        fecha: "",
        horas: "0",
        minutos: "0",
        usuario: "",
        fechaUsuario: "",
        visible: "1",
        tieneTiempoSesion: false
      },
      puntosOrdenDia: [],
      consejeros: [],
      poderLegislativo: [],
      representantes: [],
      otros: []
    };

    this.consejeros.forEach(consejero => {
      consejero.visible = "0";
    });

    this.partidos.forEach(partido => {
      partido.representantes.forEach(representante => {
        representante.visible = "0";
      });
    });

    this._obtenerSesionesService.obtenerSesiones("");
    this.sesionGuardada.emit();
  }

  formatDate() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let datetext = d.toTimeString();

    return `${[year, month, day].join("-")}  ${datetext.split(" ")[0]}`;
  }
}
