import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EdicionPuntosService {
  public puntos = [];
  public puntoPorEliminar = {} ;
  constructor() { }


  eliminarPunto(){
    let index = this.puntos.findIndex(punto=>{
      return punto['claveOrden']  == this.puntoPorEliminar['claveOrden']
    });

     this.puntos.splice(index,1);
  }

}
