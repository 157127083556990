export const titulos = {
  reporte1: {
    titulo: "1.- Resumen de participaciones.",
    descripcion: "Contiene el total de participaciones de cada uno de los integrantes del Consejo General en la sesión de que se trate, definiendo la cantidad de intervenciones y la duración de estas."
  },
  reporte2: {
    titulo: "2.- Resumen por punto del orden del día.",
    descripcion: "Este cuadro indica el nombre de los puntos del orden del día, el tiempo que duro la exposición y/o debate de cada uno de ellos finalmente nos indica, el número total de las participaciones y duración de la sesión."
  },
  reporte3: {
    titulo: "3.- Intervenciones por punto y rondas.",
    descripcion: "Esta información comprende la cantidad de participaciones de los integrantes del Consejo General en cada punto del orden del día especificando el tiempo utilizado por estos en cada una de las tres rondas posibles en las que son ventilados uno a uno, estos puntos."
  },
  reporteA: {
    titulo: "A.- Listado de participaciones.",
    descripcion: "Este listado detalla en orden cronológico cada una de las participaciones que se dieron durante la dinámica en una sesión, asignando para cada uno de los puntos el número de participantes y su duración."
  },
  reporteB: {
    titulo: "B.- Resumen de intervenciones de conducción y participación del Consejero Presidente y de la Secretaría del Consejo.",
    descripcion: "Este cuadro nos indica para cada uno de los puntos del orden del día, la cantidad y duración de las intervenciones del consejero presidente y del secretario, clasificándolas en las de conducción y las de participación entendiendo que las primeras se refieren a aquéllas cuya finalidad es guiar la dinámica de la sesión y las de partcicipación, en las que se fija su postura o se da respuesta a un planteamiento o cuestionamiento de otro miembro del Consejo General."
  },
  reporte6: {
    titulo: "6.- Mociones.",
    descripcion: "Durante el desarrollo de una sesión de Consejo General los integrantes de dicho órgano pueden solicitar una moción en orden  (artículo 20 y 21 del Reglamento de Sesiones del Consejo General), en este cuadro se describe el nombre del participante en la mesa de sesiones que solicitó la misma, y el tiempo que se aplicó como consecuencia de dicha participación."
  }
}
