import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantesService } from "../constantes/constantes.service";


@Injectable({
  providedIn: 'root'
})
export class ObtenerTipoParticipacionService {

  url;
  constructor(private httpClient: HttpClient, private _constantesService:ConstantesService) {
    this.url = this._constantesService.API_ENDPOINT;
  }

  getTipoParticipacion(){
    return this.httpClient.get(`${this.url}/obtenerTiposParticipacion`);
  }
}
