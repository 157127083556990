import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NbCardModule, NbThemeModule, NbLayoutModule, NbStepperModule, NbButtonModule} from '@nebular/theme';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EncabezadoComponent } from './components/compartido/encabezado/encabezado.component';
import { PieDePaginaComponent } from './components/compartido/pie-de-pagina/pie-de-pagina.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AntesComponent } from './components/antes/antes.component';
import { ConfiguracionSesionesComponent } from './components/configuracion-sesiones/configuracion-sesiones.component';
import { MenuCronometroComponent } from './components/menu-cronometro/menu-cronometro.component';
import { CapturaSesionComponent } from './components/captura-sesion/captura-sesion.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { TablaSesionesComponent } from './components/tabla-sesiones/tabla-sesiones.component';
import { DuranteComponent } from './components/durante/durante.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { DespuesComponent } from './components/despues/despues.component';

//Servicios
import { AuthService } from './servicios/auth/auth.service';
import { MenuService } from './servicios/menu/menu.service';
import { AuthGuard } from './servicios/auth/auth.guard';
import { TiemposService } from './servicios/tiempos/tiempos.service';
import { InterceptorService } from './servicios/interceptor/interceptor-service.service';
import { IntegrantesService } from './servicios/integrantes/integrantes.service';
import { SpinnerService } from './servicios/spinner/spinner.service';
import { GuardarSesionService } from './servicios/guardaSesion/guardar-sesion.service';
import { EditarSesionService } from './servicios/editarSesion/editar-sesion.service';
import { ObtenerCargosService } from './servicios/obtenerCargos/obtener-cargos.service';
import { ObtenerTratamientosService } from './servicios/obtenerTratamientos/obtener-tratamientos.service';
import { ObtenerRondasService } from './servicios/obtenerRondas/obtener-rondas.service';
import { ObtenerTipoParticipacionService } from './servicios/obtenerTipoParticipacion/obtener-tipo-participacion.service';
import { ObtenerSesionesService } from './servicios/obtenerSesiones/obtener-sesiones.service';
import { ObtenerEstatusVotoService } from './servicios/obtenerEstatusVoto/obtener-estatus-voto.service';
import { ObtenerTiemposSesionService } from './servicios/obtenerTiemposSesion/obtener-tiempos-sesion.service';
import { GuardarTiemposSesionService } from './servicios/guardarTiemposSesion/guardar-tiempos-sesion.service';
import { EditarTiemposSesionService } from './servicios/editarTiemposSesion/editar-tiempos-sesion.service';
import { ExcelService } from './servicios/excel/excel.service';
import { ReportespdfService } from './servicios/pdf/reportespdf.service';
import { ReportesService } from './servicios/reportes/reportes.service';



//Dependencias
import {NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {MultiWindowModule} from 'ngx-multi-window';
import { ToastrModule } from 'ngx-toastr';
import { DragulaModule } from 'ng2-dragula';

//Configuraciones
import { CustomDatepickerI18n,I18n } from "./config/ng-bootstrap-datepicker-i18n";
import { NgbDateParserFormatterEsMX } from "./config/ng-bootstrap.date-parser-formatter";
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CronometroComponent } from './components/cronometro/cronometro.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//pipes
import { StringFilterPipe } from './pipes/string-filter.pipe';
//directives
import { OnlyTextDirective } from './directives/only-text.directive';
import { OmitSpecialCharDirective } from './directives/omit-special-char.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';






@NgModule({
  declarations: [
    AppComponent,
    EncabezadoComponent,
    PieDePaginaComponent,
    LoginComponent,
    HomeComponent,
    AntesComponent,
    ConfiguracionSesionesComponent,
    MenuCronometroComponent,
    CronometroComponent,
    CapturaSesionComponent,
    StringFilterPipe,
    OnlyTextDirective,
    ConfiguracionComponent,
    TablaSesionesComponent,
    DuranteComponent,
    ReportesComponent,
    OmitSpecialCharDirective,
    DespuesComponent,
    OnlyNumberDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MultiWindowModule,
    HttpClientModule,
    NbThemeModule.forRoot(),
    NbCardModule,
    NbLayoutModule,
    NbStepperModule,
    NbButtonModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    DragulaModule.forRoot()
  ],
  providers: [
    AuthService,
    AuthGuard,
    MenuService,
    TiemposService,
    I18n,
          {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
          {provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterEsMX} ,
    IntegrantesService,
    SpinnerService,
    GuardarSesionService,
    ObtenerCargosService,
    ObtenerTratamientosService,
    ObtenerSesionesService,
    EditarSesionService,
    ObtenerRondasService,
    ObtenerTipoParticipacionService,
    ObtenerEstatusVotoService,
    ObtenerTiemposSesionService,
    GuardarTiemposSesionService,
    EditarTiemposSesionService,
    ExcelService,
    ReportespdfService,
    ReportesService,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
