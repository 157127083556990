import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantesService {
  
  //public API_ENDPOINT = "http://10.35.32.58:8083/simetis-exposicion-1.0.0";
  // public API_ENDPOINT = "http://10.35.16.80:8090/simetis-exposicion-1.0.0";
  // public API_ENDPOINT = "http://10.35.16.72:8090/simetis-exposicion-1.0.0";
  // public API_ENDPOINT = "http://10.0.182.156:8090/simetis-exposicion-1.0.0";
  //public API_ENDPOINT = "https://deoe2-seguridad.ine.mx/simetis-exposicion-1.0.0"
  //public API_ENDPOINT = "https://simetis.ine.mx/simetis-exposicion-1.0.0";
  public API_ENDPOINT = "https://app-simetis.ine.mx/simetis-exposicion-1.0.0"
  public token = '';
  constructor() { }
}
