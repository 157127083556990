import { Component, OnInit,HostListener } from "@angular/core";
import { MultiWindowService, Message } from "ngx-multi-window";
import { TiemposService } from "../../servicios/tiempos/tiempos.service";
import { SpinnerService } from '../../servicios/spinner/spinner.service'
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-menu-cronometro",
  templateUrl: "./menu-cronometro.component.html",
  styleUrls: ["./menu-cronometro.component.scss"]
})
export class MenuCronometroComponent implements OnInit {
  public idVistaCronometro: string;
  public expositorSeleccinado;
  public etapaSeleccionada = "primeraRonda";
  public usuarioSeleccionado = "";
  public tipoSesion = "Ordinaria";
  public segundosCronometro = 0;
  public miliSegundosCronometro = 0;
  public minutosCronometro = 0;
  public cronometroActivo = false;
  public temporizadorActivo = false;
  public tiempoAgotado = false;
  public tiempoRestante;
  public tituloSesion;
  public bolsaSeleccionada='';
  pregunta: NgbTimeStruct;
  lectura: NgbTimeStruct;
  mocion: NgbTimeStruct;
  primeraRonda: NgbTimeStruct;
  segundaRonda: NgbTimeStruct;
  terceraRonda: NgbTimeStruct;
  minutoSilencio: NgbTimeStruct;
  public tiempoCronometroDefault;
  public tiempos = {
    quorum: { tiempo: "00:00", titulo: "Quórum", temporizador: false },
    receso: { tiempo: "00:00", titulo: "Receso", temporizador: false },
    aprobacion: { tiempo: "00:00", titulo: "Aprobación", temporizador: false },
    tomaProtesta: {
      tiempo: "00:00",
      titulo: "Toma de Protesta",
      temporizador: false
    },
    minutoSilencio: {
      tiempo: "01:00",
      titulo: "Minuto de Silencio",
      temporizador: true
    },
    pregunta: { tiempo: "00:00", titulo: "Pregunta", temporizador: true },
    lectura: { tiempo: "00:00", titulo: "Lectura", temporizador: true },
    mocion: { tiempo: "00:00", titulo: "Moción", temporizador: true },
    primeraRonda: { tiempo: "00:00", titulo: "1ra Ronda", temporizador: true },
    segundaRonda: { tiempo: "00:00", titulo: "2da Ronda", temporizador: true },
    terceraRonda: { tiempo: "00:00", titulo: "3ra Ronda", temporizador: true }
  };
  public minutos;
  public segundos;
  //variable para generar los minutos o segundos para los inputs
  public numeros;
  public bolsa =[];
  math = Math;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    
    switch(event.key){
      case "1":
        this.limpiarBolsa('primeraRonda');this.etapaSeleccionada='primeraRonda'; this.fijarTiempo(this.etapaSeleccionada);this.transmitirEtapaSeleccionadaTemporizador();
        break;
      case "2":
        this.limpiarBolsa('segundaRonda');this.etapaSeleccionada='segundaRonda'; this.fijarTiempo(this.etapaSeleccionada);this.transmitirEtapaSeleccionadaTemporizador();
        break;
      case "3":
        this.limpiarBolsa('terceraRonda');this.etapaSeleccionada='terceraRonda'; this.fijarTiempo(this.etapaSeleccionada);this.transmitirEtapaSeleccionadaTemporizador();
        break;

    }
    
  }

  constructor(
    private multiWindowService: MultiWindowService, private _tiemposService: TiemposService, private route: ActivatedRoute, private _spinnerService :SpinnerService
  ) {
    this.numeros = Array(60)
      .fill(0)
      .map((x, i) => i);
    this.route.paramMap.subscribe(params => {
      this.tituloSesion = params.get("tipo");
      this.obtenerTiempos(this.tituloSesion);
      
      switch (this.tituloSesion) {
        case "CG":
          this.tituloSesion = `Consejo General`;
          break;
        case "JGE":
          this.tituloSesion = `Junta General Ejecutiva`;
          break;
      }
    });

    //Aperturamos conexion para escuchar mensajes de las nuevas vistas
    this.multiWindowService.onMessage().subscribe((value: Message) => {
      //recuperamos el ID de la hija que intenta la conexión
      this.idVistaCronometro = value.senderId;
      if(value.data !='Conectando'){
        let jsonBolsa = {
          "nombre" : '',
          "tiempo" : value.data
        }

        this.bolsa.push(jsonBolsa)
        console.log(this.bolsa)
      }else{
        this._spinnerService.hideSpinner();
        this.transmitirEtapaSeleccionadaTemporizador()
      }
    });
  }

  ngOnInit() {
    this._spinnerService.showSpinner();
    this.transmitir();
  }

  transmitir() {
    this._spinnerService.showSpinner();
    window.open(
      `cronometro/${this.tituloSesion}/${this.multiWindowService.id}/${this.tipoSesion}`,
      "_blank",
      "location=yes"
    );
  }

  transmitirEtapaSeleccionadaCronometro(){
    let mensaje = {
      sesion: this.tipoSesion,
      tiempo: 0,
      tipo: "C"
    };
    this.enviarMensaje(this.idVistaCronometro, mensaje);
  }

  transmitirEtapaSeleccionadaTemporizador(){
    
    let mensaje = {
      sesion: this.tipoSesion,
      tiempo: parseInt(this[this.etapaSeleccionada].minute) * 60 + parseInt(this[this.etapaSeleccionada].second),
      tipo: "T"
    };
    console.log(mensaje)
    this.enviarMensaje(this.idVistaCronometro, mensaje);

  }
  //bolsa

  transmitirEtapaSeleccionadaBolsa(indice){
    let mensaje = {
      sesion: this.tipoSesion,
      tiempo: this.bolsa[indice]['tiempo'],
      tipo: "T"
    };
    this.bolsa.splice(indice, 1);

    this.enviarMensaje(this.idVistaCronometro, mensaje);
  }

  limpiarBolsa(etapa){
    if(this.bolsaSeleccionada == '' || this.bolsaSeleccionada != etapa){
      this.bolsaSeleccionada = etapa
      this.bolsa = [];
    }
    
  }

  eliminarTiempoBolsa(indice){
    
    this.bolsa.splice(indice, 1);
  }

  obtenerTiempos(tipo) {
    this._tiemposService.getTiempos(tipo).subscribe(data => {
      // console.log(data)
      this.tiempoCronometroDefault = JSON.parse(JSON.stringify(data[0]));
      this.tiempoCronometroDefault = data;
      this.tiempoCronometroDefault = data
      // console.log(this.tiempoCronometroDefault)
      this.minutoSilencio = data[0]["minutoSilencio"];
      this.pregunta = data[0]["pregunta"];
      this.lectura = data[0]["lectura"];
      this.mocion = data[0]["mocion"];
      this.primeraRonda = data[0]["primeraRonda"];
      this.segundaRonda = data[0]["segundaRonda"];
      this.terceraRonda = data[0]["terceraRonda"];
      // this.minutoSilencio = data["minutoSilencio"];
      // this.pregunta = data["pregunta"];
      // this.lectura = data["lectura"];
      // this.mocion = data["mocion"];
      // this.primeraRonda = data["primeraRonda"];
      // this.segundaRonda = data["segundaRonda"];
      // this.terceraRonda = data["terceraRonda"];
      // console.log("sdaasads")
      this.fijarTiempo(this.etapaSeleccionada)
    });
  }
  
  //Tiempo inputs
  fijarTiempo(etapa) {
    
    this.minutos = this[etapa].minute;
    this.segundos = this[etapa].second;
  }
  cambiarTiempoPredefinido(etapa) {
    this[etapa].minute = this.minutos;
    this[etapa].second = this.segundos;
  }

  enviarMensaje(id, mensaje) {
    this.multiWindowService.sendMessage(id, "customEvent", mensaje).subscribe(
      () => {
        console.log("Message sended, to " + id);
      },
      error => {
        console.log("Message sending failed, error: " + error);
      },
      () => {
        console.log("Message successfully delivered");
      }
    );
  }
}
