import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[OnlyText]"
})
export class OnlyTextDirective {
  constructor(public el: ElementRef) {
    this.el.nativeElement.onkeypress = evt => {
      //permitimos unicamente valores entre a y z mas el punto
      const regularExpresion = /^([a-zA-Z.])$/ ;
      if (!regularExpresion.test(evt.key)) {
        evt.preventDefault();
      }
    };
  }
}
