import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantesService } from "../constantes/constantes.service";

@Injectable({
  providedIn: 'root'
})
export class TiemposService {
  url;
  constructor(private httpClient: HttpClient, private _constantesService:ConstantesService) {
    this.url = this._constantesService.API_ENDPOINT;
  }

  getTiempos(tipo){
    //return this.httpClient.get(`./assets/json/tiempos${tipo}.json`);
    return this.httpClient.get(`${this.url}/obtenerTiemposCronometro?tipoSesion=${tipo}`)
  }

  postTiempos(tiempos, sesion){
    let jsonTiempos = {
      "cadenaAtributos" : tiempos,
      "tipoSesion" : sesion
    }
    var getting = document.cookie;
    console.log(JSON.stringify(jsonTiempos))
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.httpClient.post(`${this.url}/guardarTiempoSesion`,jsonTiempos)
  }
  

}
