import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringFilter',
  pure: false
})
export class StringFilterPipe implements PipeTransform {

  transform( items, filter ) {
    if (!items || !filter) {
      return items;
    }
    // filter items by camp
    
    return items.filter((item) => this.filterByField(item, filter));
  }

  filterByField( item, filter ) {
    for (let field in filter) {
      if (filter[field]) {
        if (typeof item[field] === 'string') {
          if (item[field].toLowerCase() !== filter[field].toLowerCase()) {
            return false;
          }
        }else if (typeof item[field] === 'number') {
          if (item[field] != filter[field]) {
            return false;
          }
        }
      }
    }
    return true;
  }

}
