import { Component, OnInit } from '@angular/core';
import { ObtenerSesionesService } from '../../servicios/obtenerSesiones/obtener-sesiones.service';
import { ObtenerRondasService } from '../../servicios/obtenerRondas/obtener-rondas.service';
import { ObtenerTipoParticipacionService } from '../../servicios/obtenerTipoParticipacion/obtener-tipo-participacion.service';
import { ObtenerEstatusVotoService } from '../../servicios/obtenerEstatusVoto/obtener-estatus-voto.service';
import { ObtenerTiemposSesionService } from '../../servicios/obtenerTiemposSesion/obtener-tiempos-sesion.service';
import { EditarTiemposSesionService } from '../../servicios/editarTiemposSesion/editar-tiempos-sesion.service';
import { MenuService } from '../../servicios/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '../../servicios/spinner/spinner.service';

@Component({
  selector: 'app-despues',
  templateUrl: './despues.component.html',
  styleUrls: ['./despues.component.scss']
})
export class DespuesComponent implements OnInit {
  estatusVoto;
  rondas ;
  tipoParticipaciones ;
  sesionSeleccionada:boolean = false;
  filtroParticipacion = {
    "idTipoParticipacion" : "",
    "idRonda" : ""
  }
  estatusPunto;
  tiempos;
  tiemposPorPunto;
  puntoId = "0";
  puntoAEditar;
  public labels = {
    previousLabel: "",
    nextLabel: ""
  };
  constructor(private _menuService:MenuService, private _obtenerTipoParticipacionService:ObtenerTipoParticipacionService, private _obtenerRondasService:ObtenerRondasService,private _obtenerEstatusVotoService:ObtenerEstatusVotoService,
  private _obtenerSesionesService: ObtenerSesionesService, private _obtenerTiemposSesionService:ObtenerTiemposSesionService, private toastr: ToastrService, private _spinnerService:SpinnerService,
  private _editarTiemposSesionService:EditarTiemposSesionService) {
    this.obtenerTipoParticipaciones();
    this.obtenerRondas();
    this.obtenerEstatusVoto();
  }

  ngOnInit() {
    this._menuService.verMenu(true);
  }
  
  obtenerEstatusVoto(){
    this._spinnerService.showSpinner();
      this._obtenerEstatusVotoService.getEstatusVoto().subscribe(data=>{
        this.estatusVoto=data;
        this._spinnerService.hideSpinner();
      },err=>{
        console.log(err);
        this._spinnerService.hideSpinner();
      })
  }
  obtenerRondas(){
    this._spinnerService.showSpinner();
      this._obtenerRondasService.getRondas().subscribe(data=>{
        this.rondas=data;
        this._spinnerService.hideSpinner();
      },err=>{
        console.log(err);
        this._spinnerService.hideSpinner();
      })
  }

  obtenerTipoParticipaciones(){
    this._spinnerService.showSpinner();
      this._obtenerTipoParticipacionService.getTipoParticipacion().subscribe(data=>{
        this.tipoParticipaciones=data;
        this._spinnerService.hideSpinner();
      },err=>{
        console.log(err);
        this._spinnerService.hideSpinner();
      })
  }

  sesion(sesion) {
    this.sesionSeleccionada = true;
    this.getTiemposSesiones(sesion);
  }

  cancelarEdicion() {
    this.sesionSeleccionada = false;
  }

  getTiemposSesiones(idSesion) {
    this._spinnerService.showSpinner();
    this._obtenerTiemposSesionService.getTiemposPorSesion(idSesion).subscribe(data =>{
      this.tiempos  = data;
      this.filtroPuntos();
      this._spinnerService.hideSpinner();
    }, err=>{
      console.log(err);
      this._spinnerService.hideSpinner();
    });
  }

  //edicionPuntos
  filtroPuntos(){
    this.tiemposPorPunto = [];
    this.tiemposPorPunto = this.tiempos.tiemposSesion.filter(tiempoParticipante =>{
      tiempoParticipante['minutos'] = tiempoParticipante['tiempo']  > 59 ?  Math.floor(tiempoParticipante['tiempo'] / 60) : 0;
      tiempoParticipante['segundos'] = tiempoParticipante['tiempo'] % 60;
      return tiempoParticipante.idPuntoOrden == this.tiempos.puntosSesion[this.puntoId]['idPuntoOrden']
     });

  }

  edicionTipoRonda(turno,idTipoRonda){
    let index = this.tiempos.tiemposSesion.findIndex( tiempo => tiempo.turno  == turno );
    this.tiempos.tiemposSesion[index]['idTipoRonda'] = parseInt(idTipoRonda);
  }

  edicionTipoParticipacion(turno,idTipoParticipacion){
    let index = this.tiempos.tiemposSesion.findIndex( tiempo => tiempo.turno  == turno );
    this.tiempos.tiemposSesion[index]['idTipoParticipacion'] = parseInt(idTipoParticipacion);
  }

  edicionTiempo(turno, minutos, segundos ){

    let index = this.tiempos.tiemposSesion.findIndex( tiempo => tiempo.turno  == turno );
    if(minutos > 59 ){
        this.tiempos.tiemposSesion[index]['minutos'] = 59;
    }
    if(segundos > 59 ){
        this.tiempos.tiemposSesion[index]['segundos'] = 59;
    }
    if(segundos !=='' && minutos !== ''){
      this.tiempos.tiemposSesion[index]['tiempo'] = parseInt(minutos) * 60 + parseInt(segundos);
    }
    
  }

  actualizarTiempos(){
    this._spinnerService.showSpinner();
    this._editarTiemposSesionService.actualizarTiemposSesion(this.tiempos).subscribe(data => {
      if(data['codigo'] == "201"){
        this.toastr.success('¡Los tiempos han sido actualizados exitosamente!');
      }else{
        this.toastr.warning('¡Los tiempos no se actualizaron!');
      }
      this._spinnerService.hideSpinner();
    }, err =>{
      console.log(err);
      this._spinnerService.hideSpinner();
    })
  }
}
