import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ConstantesService } from '../constantes/constantes.service';

@Injectable({
  providedIn: 'root'
})
export class GuardarTiemposSesionService {
  url;
  constructor(private httpClient: HttpClient, private _constantesService: ConstantesService) {
    this.url = this._constantesService.API_ENDPOINT;
  }

  postTiemposSesion(jsonTiemposSesion) {
    return this.httpClient.post(`${this.url}/guardarTiemposPorParticipante`, jsonTiemposSesion, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

}
