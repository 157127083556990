import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SpinnerService {
  public banderaSpinner: boolean = false;
  constructor() {}

  showSpinner() {
    this.banderaSpinner = true;
  }

  hideSpinner() {
    this.banderaSpinner = false;
  }
}
