import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[omitSpecialChar]'
})
export class OmitSpecialCharDirective {

    constructor(public el: ElementRef) {
        this.el.nativeElement.onkeypress = (evt) => {
            
            if (evt.which == 92 || evt.which == 34 || evt.which == 39 || evt.which == 60 || evt.which == 62 || evt.which == 38 || evt.which == 35 || evt.which == 123 || evt.which == 125 || evt.which == 91 || evt.which == 93 || evt.which == 124) {
              evt.preventDefault();
            }
        };
    }

}
