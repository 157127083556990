import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  get isMenu() {
    return this.menu.asObservable();
  }

  constructor() { }

  verMenu(bandera:boolean) {
    this.menu.next(bandera);
  }
  

}
