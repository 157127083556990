import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ConstantesService } from '../constantes/constantes.service';

@Injectable({
  providedIn: 'root'
})
export class CrearPartidoService {

  url;
  constructor(private httpClient: HttpClient, private _constantesService:ConstantesService) {
    this.url = this._constantesService.API_ENDPOINT;
  }

  postPartido(jsonPartido){
    console.log(`${this.url}/guardarPartidoRepresentantes`)
    return this.httpClient.post(`${this.url}/guardarPartidoRepresentantes`, jsonPartido, {
      headers: new HttpHeaders({
           'Content-Type':  'application/json'
         })
    })
  }
}
