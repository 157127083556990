import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantesService } from "../constantes/constantes.service";

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  url;
  constructor(private httpClient: HttpClient, private _constantesService:ConstantesService) {
    this.url = this._constantesService.API_ENDPOINT;
  }

  getReportesPorSesion(id){
     return this.httpClient.get(`${this.url}/obtenerResumenParticipantes?idSesion=${id}`);
    //return this.httpClient.get(`${this.url}/obtenerResumenParticipantes?idSesion=21&idPuntoOrden=53`);
  }
}
