import { Observable } from 'rxjs';
import { AuthService } from '../../../servicios/auth/auth.service';
import { MenuService } from '../../../servicios/menu/menu.service';
import { SpinnerService } from '../../../servicios/spinner/spinner.service';
import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import {  Router ,ActivatedRoute } from '@angular/router';
import { log } from 'util';

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.scss']
})
export class EncabezadoComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  isMenu$: Observable<boolean>;
  public seccion: string;
  public usuario: string;
  public rolUsuario: string;
  public tipoConfiguracion;
  constructor(private _authService: AuthService, private _menuService: MenuService, private router: Router, private route: ActivatedRoute,
     private cd:ChangeDetectorRef, private _spinnerService:SpinnerService ) { }

  ngOnInit() {

    this.isLoggedIn$ = this._authService.isLoggedIn;

    this.isMenu$ = this._menuService.isMenu;

  }

  ngAfterViewInit(){
  }
  
  onLogout() {
    
    this._authService.logout();
    
  }
  
  
  ngAfterViewChecked() {
    
    
    this.seccion = this.router.url;
    
    this.route.paramMap.subscribe(params => {

      this.tipoConfiguracion = params.get("tipo");
      this.usuario = this._authService.nombreUsuario;
      this.rolUsuario = this._authService.rol ||"";
       if(this.router.url != '/' && this.router.url != '/login' && !this.router.url.includes('/cronometro') ){
         this._authService.isLoggedIn.subscribe((value) => {
          
              // if (!value){
              //   this.router.navigate(['/login']);
              // }
           })
      }
       switch(this.router.url){
         case "/configuracion-sesiones/CG":
         case"/menu-cronometro/CG":
           this.tipoConfiguracion = `/configuracion-sesiones/CG`;
         break;
         case "/configuracion-sesiones/JGE":
         case"/menu-cronometro/JGE":
           this.tipoConfiguracion = `/configuracion-sesiones/JGE`;
         break;
         default:
            this.tipoConfiguracion = `/configuracion`;
       }
       
    })

    
    this.cd.detectChanges();
  }
}
