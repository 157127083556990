import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as Logo from './imagenIne.js';
import * as Encabezados from './titulos.js';
import * as TablaTipo from './headersTable.js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class ReportespdfService {

  constructor() {

  }
  generatePdf(data, arregloReportes) {
    //Iteramos sobre las opciones seleccionadas
    arregloReportes.forEach(llaveArreglo => {

      //Concatenamos el Id del repo para seleccionar la clave dentro del JSON
      let llaveReporte = `reporte${llaveArreglo}`
      let reporte = data[llaveReporte];

      let reporteCuerpo;
      let tablas = [];
      //consultamos el tipo de arreglo puesto que no son del mismo tipo
      if (llaveReporte.includes("3") || llaveReporte.includes("B")) {

        let tablasPorPunto = [];



        reporte.forEach(punto => {
          let header = this.clone(TablaTipo.headers[llaveReporte]);

          reporteCuerpo = this.valoresTabla(punto["valores"]);

          let cuerpoTabla = [{
            //título o descripción del punto
            columns: [
              [{
                alignment: 'justify',
                text: punto.descripcion,
                margin: [0, 10,0,10]
              }]
            ],

          },
          {
            //encabezado  para cada tabla de acuerdo a a llave del reporte
            table: {
              widths: header.widths,
              headerRows: header.headerRows,
              body: header.body,
            }
          },
          //Cuerpo reporte Por punto
          {
            table: {
              widths: TablaTipo.headers[llaveReporte].widths,
              body: reporteCuerpo
            }
          }]


          tablas.push(cuerpoTabla);

        });
        // console.log(`totales${llaveArreglo}`)
        // console.log(data)
      let totales = [ data[`totales${llaveArreglo}`] ]

        let cuerpoFooter = this.valoresTabla(totales);
        let anchoTabla;
        if(llaveReporte.includes("3") ){
          anchoTabla = [80, 20, 50, 20, 50, 20, 50, 20, 50, 20, 40]
        }else{
          anchoTabla = TablaTipo.headers[llaveReporte].widths
        }
         //Footer tabla
        let footer =[
          {
            //título o descripción del punto
            columns: [
              [{
                alignment: 'justify',
                text: "TIEMPO TOTAL",
                margin: [0, 20, 0, 0],
                bold: true
              }]
            ]

          },{
          table: {
            widths: anchoTabla,
            headerRows: 1,
            margin: [0, 20],
            body: cuerpoFooter
          }
        }];

        //tablas.push(titulo);
        tablas.push(footer);

      } else {
        //convertimos el Json en un array para generar las filas de la tablas
        reporteCuerpo = this.valoresTabla(reporte);

        tablas = [
          {
            //encabezado  para cada tabla de acuerdo a a llave del reporte
            table: {
              widths: TablaTipo.headers[llaveReporte].widths,
              headerRows: TablaTipo.headers[llaveReporte].headerRows,
              body: TablaTipo.headers[llaveReporte].body,
            }
          },
          {
            //cuerpo reporte
            table: {
              widths: TablaTipo.headers[llaveReporte].widths,
              body: reporteCuerpo
            }
          }
        ]
      }


      const documentDefinition = {
        content: [
          {
            image: Logo.logoINEBase64,
            width: 175,
            height: 80,
            alignment: 'center'
          },
          {
            columns: [
              [{
                alignment: 'justify',
                text: Encabezados.titulos[llaveReporte].titulo,
                margin: [0, 10, 0, 5],
                bold: true
              },
              {
                alignment: 'justify',
                text: Encabezados.titulos[llaveReporte].descripcion,
                margin: [20, 0, 5, 20]
              }
              ]
            ]
          },
          tablas
        ]
      };
      pdfMake.createPdf(documentDefinition).download(`${llaveReporte}.pdf`);
      //  }
      //}

    })

  }

  valoresTabla(reporte) {
    let reporteBody = []
    for (const [key, filasReporte] of Object.entries(reporte)) {

      let fila = [];
      for (const [key, value] of Object.entries(filasReporte)) {
        if (key != "claveOrden"){

          let text = {}

          switch(key){
            case 'integrantes':
            case 'integrante':
            text = {
              text: "" + value,
              alignment: 'left',
              fontSize: 10
            }
            break;
            case 'participaciones':
            case 'estatus':
            text = {
              text: "" + value,
              alignment: 'center',
              fontSize: 10
            }
            break;
            case 'punto':
            text = {
              text: "" + value,
              alignment: 'justify',
              fontSize: 10
            }
            break;
            default:
            text = {
              text: "" + value,
              alignment: 'right',
              fontSize: 10
            }
            break;
          }



          fila.push(text);
        }

      }
      reporteBody.push(fila)
    }
    return reporteBody;
  }

  clone(obj) {
    let copiaJson = JSON.parse(JSON.stringify(obj));
    return copiaJson;
  }
}
