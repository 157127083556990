import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantesService } from "../constantes/constantes.service";
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ObtenerSesionesService {

  url;
  objetoSesiones;
  fechas = [];
  idSesiones = [];
  constructor(private httpClient: HttpClient, private _constantesService: ConstantesService, private _spinnerService: SpinnerService) {
    this.url = this._constantesService.API_ENDPOINT;
  }
  getSesiones() {
    return this.httpClient.get<any[]>(`${this.url}/obtenerSesiones`);
  }

  getSesion(id) {
    return this.httpClient.get(`${this.url}/obtenerSesion?idSesion=${id}`);
  }

  obtenerSesiones(url) {
    this._spinnerService.showSpinner();
    this.getSesiones().subscribe(
      data => {
        this.objetoSesiones = [];
        this.fechas = [];
        this.idSesiones = [];
        if (url == '/despues' || url == '/reportes') {
          let array = data.filter(sesion => sesion.tieneTiempoSesion);

          this.tipoSesiones(array);
        } else {
          this.tipoSesiones(data);
        }
        this._spinnerService.hideSpinner();
      },
      err => {
        this._spinnerService.hideSpinner();
        console.log(err);
      }
    );
  }

  tipoSesiones(data) {

    this.objetoSesiones = data;
    this.objetoSesiones.forEach((sesion, i) => {
      //Creamos temp para vista
      switch (sesion.tipoSesion) {
        case "ord":
          sesion["tipo"] = "Ordinaria";
          break;
        case "ext":
          sesion["tipo"] = "Extraordinaria";
          break;
        case "exu":
          sesion["tipo"] = "Extraordinaria Urgente";
          break;
        default:
          sesion["tipo"] = "Especial";
          break;
      }
      // //creamos arreglo de fechas
      this.arregloFechas(sesion.fechaSesion);
      // //creamos arreglo de sesiones
      this.arregloSesiones(sesion.numeroSesion);

    });
  }
  arregloFechas(fecha) {
    if (!this.fechas.includes(fecha)) {
      this.fechas.push(fecha);
    }
  }

  arregloSesiones(id) {
    if (!this.idSesiones.includes(id)) {
      this.idSesiones.push(id);
    }
  }
}
