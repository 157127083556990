import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ConstantesService } from '../constantes/constantes.service';

@Injectable({
  providedIn: 'root'
})
export class EditarConsejeroService {

  url;
  constructor(private httpClient: HttpClient, private _constantesService:ConstantesService) {
    this.url = this._constantesService.API_ENDPOINT;
  }

  actualizarConsejero(jsonConsejero){
    
    return this.httpClient.post(`${this.url}/actualizarConsejero`, jsonConsejero, {
      headers: new HttpHeaders({
           'Content-Type':  'application/json'
         })
    })
  }
}
