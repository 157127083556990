import { Component, OnInit } from '@angular/core';
import { TiemposService } from '../../servicios/tiempos/tiempos.service';
import { ActivatedRoute, Router } from "@angular/router";



@Component({
  selector: 'app-configuracion-sesiones',
  templateUrl: './configuracion-sesiones.component.html',
  styleUrls: ['./configuracion-sesiones.component.scss']
})
export class ConfiguracionSesionesComponent implements OnInit {
  public tiempos;
  public titulo = "";
  public tituloSesion = "";
  public campo:String = 'pregunta';
  numeros;
  public minutos;
  public segundos;
  
  constructor(private _tiemposService: TiemposService, private route: ActivatedRoute, private router: Router) {

    this.numeros = Array(60).fill(0).map((x,i)=>i);
    this.route.paramMap.subscribe(params => {
      this.tituloSesion = params.get("tipo");
      this.obtenerTiempos(this.tituloSesion);
      switch(this.tituloSesion ){
        case "CG":
          this.titulo = `Consejo General`
        break;
        case "JGE":
          this.titulo = `Junta General Ejecutiva`
        break;
      }
    })

 
  }

  ngOnInit() {
    
  }
  

  obtenerTiempos(tituloSesion){
    this._tiemposService.getTiempos(tituloSesion).subscribe((data)=>{

      this.tiempos = data[0];
    })
  }

  cambaiarTipo(tipo){
    this.campo = tipo;
    this.minutos = this[tipo].minute;
    this.segundos = this[tipo].second;

  }

  guardarNuevosTiempos(sesion){


    this._tiemposService.postTiempos(this.tiempos,sesion).subscribe((data)=>{
      console.log("Nuevas configuraciones guardadas");
      this.router.navigate([`/menu-cronometro/${this.tituloSesion}`]);
    })
  }

}
